/**
 * Created by Slava on 21.06.2024
 */

ExclamationViewBase.prototype.createMsg = cleverapps.extendFunc(ExclamationViewBase.prototype.createMsg, function (message, font) {
    var text = this.createText(message, font);
    text.setRotation(cleverapps.styles.Exclamations.rotation);

    text.setScale(0.5);
    text.opacity = 0;

    text.runAction(new cc.Sequence(
        new cc.DelayTime(0.4),
        new cc.Spawn(
            new cc.FadeIn(0.3),
            new cc.ScaleTo(0.3, 1.3)
        ),
        new cc.ScaleTo(0.1, 0.9),
        new cc.ScaleTo(0.1, 1.1),
        new cc.ScaleTo(0.1, 1),
        new cc.DelayTime(0.5),
        new cc.ScaleTo(0.1, 1.1),
        new cc.Spawn(
            new cc.FadeOut(0.2),
            new cc.ScaleTo(0.2, 0.5)
        ),
        new cc.RemoveSelf()
    ));
});

ExclamationViewBase.prototype.createText = cleverapps.extendFunc(ExclamationViewBase.prototype.createText, function (message, font) {
    var text = cleverapps.UI.generateTTFText(message, font);
    text.fitTo(this.width);

    text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
    text.setPositionRound(this.width / 2, this.height / 2);
    this.addChild(text);

    return text;
});