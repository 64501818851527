/**
 * Created by Slava on 26.07.2024
 */

var TutorialContext = {
    getForceElement: function (data) {
        var scene = cleverapps.scenes.getRunningScene();

        if (data.roll !== undefined) {
            return scene.rollButton;
        }

        if (data.dice !== undefined) {
            return cleverapps.aims.getTarget("dice" + data.dice);
        }

        if (data.slot !== undefined) {
            return scene.yatzyField.slots[data.slot];
        }

        if (data.bonus || data.no_element) {
            return ForceView.NO_ELEMENT;
        }
    },

    getForceOptions: function (data) {
        var options = {};
        if (data.force.pointer) {
            if (data.slot !== undefined) {
                options.pointerDirection = data.slot < 6 ? PointerView.DIRECTIONS.LEFT : PointerView.DIRECTIONS.RIGHT;
            }
            if (data.dice !== undefined) {
                options.pointerDirection = PointerView.DIRECTIONS.DOWN;
            }
            if (data.roll !== undefined) {
                options.pointerDirection = PointerView.DIRECTIONS.DOWN;
            }
        }
        return options;
    },

    getHighlightElements: function () {
        var scene = cleverapps.scenes.getRunningScene();

        var items = [];

        items.push(scene.dicesView);
        items.push(scene.yatzyField);
        items.push(scene.rollButton);
        items.push(cleverapps.scenes.getMovingNode());

        return items;
    },

    getImportantNodes: function (data) {
        if (data.noImportantNodes) {
            return [];
        }

        var scene = cleverapps.scenes.getRunningScene();

        var items = [];

        items.push(scene.dicesView);
        items.push(scene.yatzyField);
        items.push(scene.rollButton);

        return items;
    },

    onStartStep: function (data) {
        var scene = cleverapps.scenes.getRunningScene();

        if (!Game.currentGame.hintSlots) {
            Game.currentGame.hintSlots = [];
        }

        if (data.slot !== undefined) {
            Game.currentGame.hintSlots.push(data.slot);
            var yatzyPlayed = Game.currentGame.slots[Slot.YATZY].used;
            var score = Game.currentGame.slots[data.slot].calcScore(yatzyPlayed);
            Game.currentGame.slots[data.slot].setScore(score);
            if (data.slot !== Slot.YATZY) {
                Game.currentGame.highlightCombo();
            }
            scene.yatzyField.slots[data.slot].hintScore();
            scene.yatzyField.slots[data.slot].wiggleOn();
            cleverapps.tooltipManager.remove(scene.yatzyField.slots[data.slot].icon);
        }

        if (data.dice !== undefined) {
            var dice = cleverapps.aims.getTarget("dice" + data.dice);
            dice.wiggleOn();
        }

        if (!data.hideDicesDescription) {
            scene.dicesView.showDescription();
        }
    },

    onFinishStep: function (data) {
        var scene = cleverapps.scenes.getRunningScene();

        if (!data) {
            return;
        }

        if (data.slot !== undefined) {
            scene.yatzyField.slots[data.slot].wiggleOff();
        }

        if (data.dice !== undefined) {
            var dice = cleverapps.aims.getTarget("dice" + data.dice);
            dice.wiggleOff();
        }
    },

    onFinishScenario: function () {
        Game.currentGame.hintSlots = undefined;
    }
};
