/**
 * Created by slava on 04.10.2023
 */

var SimpleProgressAltView = cc.Node.extend({
    ctor: function () {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        var msg = "background_name_" + cleverapps.meta.selectedLocationId;
        var text = this.text = cleverapps.UI.generateOnlyText(msg, cleverapps.styles.FONTS.BACKGROUND_NAME_TEXT);
        text.fitTo(cleverapps.styles.SimpleProgressAltView.text.width);
        this.addChild(text);

        var starCountView = this.starCountView = new SimpleStarCountView();
        this.addChild(starCountView);

        cleverapps.meta.on("moveNext", this.changeCurrent.bind(this), this);
        cleverapps.meta.getMainObject().on("updateProgress", this.updateProgress.bind(this), this);

        this.updateSize();
        this.setupChildren();
    },

    updateSize: function () {
        var styles = cleverapps.styles.SimpleProgressAltView;
        if (this.resolurionMod !== cleverapps.resolution.mode) {
            this.createBg();
            this.createScroll();
        }
        this.resolurionMod = cleverapps.resolution.mode;

        this.setContentSize2(this.scroll.width * this.scroll.scale, this.scroll.height * this.scroll.scale);

        this.bgFrame1.removeFromParent();
        this.bgFrame1 = cleverapps.UI.createScale9Sprite(cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? bundles.simple.frames.frame_vertical_1 : bundles.simple.frames.frame_1);
        this.bgFrame1.setContentSize2(styles.background.size.frame1);
        this.addChild(this.bgFrame1, -1);

        this.bgFrame2.removeFromParent();
        this.bgFrame2 = cleverapps.UI.createScale9Sprite(cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? bundles.simple.frames.frame_vertical_2 : bundles.simple.frames.frame_2);
        this.bgFrame2.setContentSize2(styles.background.size.frame2);
        this.addChild(this.bgFrame2, -1);

        this.text.removeFromParent();
        var msg = "background_name_" + cleverapps.meta.getMainObject().locationId;
        this.text = cleverapps.UI.generateOnlyText(msg, cleverapps.styles.FONTS.BACKGROUND_NAME_TEXT);
        this.text.setPositionRound(styles.text.position);
        this.text.fitTo(styles.text.width);
        this.addChild(this.text);
    },

    setupChildren: function () {
        this.setPositionRound(cleverapps.styles.SimpleProgressAltView.position);
        this.scroll.setPositionRound(cleverapps.styles.SimpleProgressAltView.scroll.position);
        this.bgFrame1.setPositionRound(cleverapps.styles.SimpleProgressAltView.background.position.frame1);
        this.bgFrame2.setPositionRound(cleverapps.styles.SimpleProgressAltView.background.position.frame2);
        this.text.setPositionRound(cleverapps.styles.SimpleProgressAltView.text.position);
        this.starCountView.setPositionRound(cleverapps.styles.SimpleProgressAltView.starCount);
    },

    createBg: function () {
        var styles = cleverapps.styles.SimpleProgressAltView;
        if (this.bgFrame1) {
            this.bgFrame1.removeFromParent();
            this.bgFrame2.removeFromParent();
        }

        var bgFrame1 = this.bgFrame1 = cleverapps.UI.createScale9Sprite(cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? bundles.simple.frames.frame_vertical_1 : bundles.simple.frames.frame_1);
        bgFrame1.setContentSize2(styles.background.size.frame1);
        this.addChild(bgFrame1, -1);

        var bgFrame2 = this.bgFrame2 = cleverapps.UI.createScale9Sprite(cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? bundles.simple.frames.frame_vertical_2 : bundles.simple.frames.frame_2);
        bgFrame2.setContentSize2(styles.background.size.frame2);
        this.addChild(bgFrame2, -1);
    },

    createIcons: function () {
        this.items = [];

        var totalCount = cleverapps.meta.getLastLocationId() + 1;

        for (var i = 0; i < totalCount; i++) {
            var item = this.createItem(i);
            var lineBefore = this.createLine(i);
            var lineAfter = this.createLine(i + 1);

            var layout = new cleverapps.Layout([lineBefore, item, lineAfter], {
                direction: cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? cleverapps.UI.VERTICAL : cleverapps.UI.HORIZONTAL,
                margin: cleverapps.styles.SimpleProgressAltView.line.overlap,
                reversed: cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL
            });
            layout.item = item;

            this.items.push(layout);
        }

        return [this.createEmptyItem(this.items[0].getContentSize())].concat(this.items).concat([this.createEmptyItem(this.items[0].getContentSize())]);
    },

    showAnimation: function (silent) {
        if (silent) {
            this.visible = true;
            this.scale = 1;
            this.opacity = 255;
            return;
        }

        this.setCascadeOpacityEnabledRecursively(true);

        this.runAction(
            new cc.Sequence(
                new cc.Hide(),
                new cc.FadeOut(0),
                new cc.ScaleTo(0, 0.6),
                new cc.Show(),
                new cc.Spawn(
                    new cc.ScaleTo(0.2, 1).easing(cc.easeOut(2)),
                    new cc.FadeIn(0.2).easing(cc.easeOut(2))
                )
            )
        );
    },

    hideAnimation: function (silent) {
        if (silent) {
            this.visible = false;
            return;
        }

        this.setCascadeOpacityEnabledRecursively(true);

        this.runAction(
            new cc.Sequence(
                new cc.Spawn(
                    new cc.ScaleTo(0.1, 0.7).easing(cc.easeIn(2)),
                    new cc.FadeOut(0.1)
                ),
                new cc.Hide()
            )
        );
    },

    createScroll: function () {
        if (this.scroll) {
            this.scroll.removeFromParent();
        }

        var styles = cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? cleverapps.styles.SimpleProgressAltView.scroll.vertical : cleverapps.styles.SimpleProgressAltView.scroll.horizontal;

        var direction = cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? cleverapps.UI.VERTICAL : cleverapps.UI.HORIZONTAL;
        var reversed = cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL;
        var width = styles.width;
        var height = styles.height;
        var items = this.createIcons();

        var scroll = this.scroll = new SnapScrollView(items, {
            direction: direction,
            pagesCount: cleverapps.styles.SimpleProgressAltView.scroll.pagesCount,
            height: height,
            width: width,
            reversed: reversed,
            scrollBarEnabled: false
        });

        if (styles.scale) {
            scroll.setScale(styles.scale);
        }

        var current = cleverapps.meta.getMainObject().locationId;
        scroll.scrollTo(this.items[current]);

        scroll.onUpdatePositionListener = this.changeItemZoom.bind(this, scroll);
        this.changeItemZoom(scroll);
        this.addChild(scroll);
    },

    changeItemZoom: function (scroll) {
        var zoom = cleverapps.styles.SimpleProgressAltView.scroll.zoom;
        var pos = scroll.getContainerPosition();

        var offset;
        if (scroll.dir === cleverapps.UI.ScrollView.DIR_HORIZONTAL) {
            offset = (pos.x + (scroll.innerContent.width - scroll.pageSize.width) / 2) / scroll.pageSize.width;
        } else {
            offset = (pos.y + (scroll.innerContent.height - scroll.pageSize.height) / 2) / scroll.pageSize.height;
        }

        var lower = cleverapps.clamp(Math.floor(offset), 0, scroll.getPagesAmount() - 1);
        var upper = Math.min(lower + 1, scroll.getPagesAmount() - 1);
        var center = cleverapps.clamp(Math.round(offset), 0, scroll.getPagesAmount() - 1);

        scroll.items[center].replaceParentSamePlace(scroll.items[center].parent);

        if (scroll.items[upper].item && !scroll.items[upper].item.cantScale) {
            scroll.items[upper].item.setScale(((offset - lower) * (zoom - 1) + 1));
        }
        if (scroll.items[lower].item && !scroll.items[lower].item.cantScale) {
            scroll.items[lower].item.setScale(((upper - offset) * (zoom - 1) + 1));
        }
    },

    createEmptyItem: function (size) {
        var container = new cc.Node();
        container.setAnchorPoint(0.5, 0.5);
        container.setContentSize2(size);
        return container;
    },

    createLine: function (index) {
        var current = cleverapps.meta.getMainObject().locationId;
        var line = new cc.Node();
        var totalCount = cleverapps.meta.getLastLocationId() + 1;

        if (index > 0 && index < totalCount) {
            if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
                line = cleverapps.UI.createScale9Sprite(index <= current ? bundles.simple.frames.line_bg_vertical_completed : bundles.simple.frames.line_bg_vertical_uncompleted);
            } else {
                line = cleverapps.UI.createScale9Sprite(index <= current ? bundles.simple.frames.line_bg_completed : bundles.simple.frames.line_bg_uncompleted);
            }
        }
        line.setContentSize2(cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? cleverapps.styles.SimpleProgressAltView.line.vertical : cleverapps.styles.SimpleProgressAltView.line.horizontal);
        line.setLocalZOrder(-1);
        return line;
    },

    createLineBar: function () {
        var line = cleverapps.UI.createScale9Sprite(cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? bundles.simple.frames.line_bg_vertical_completed : bundles.simple.frames.line_bg_completed_bar);

        line.setContentSize2(cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? cleverapps.styles.SimpleProgressAltView.line.vertical : cleverapps.styles.SimpleProgressAltView.line.horizontal);
        line.setLocalZOrder(-1);
        return line;
    },

    createIcon: function (index) {
        var current = cleverapps.meta.getMainObject().locationId;
        if (index <= current + 1) {
            var icon = new cc.Sprite(bundles.simple.frames["background_" + index]);

            if (index === current + 1) {
                cleverapps.UI.convertToGrayShape(icon);
            }

            return icon;
        }

        return new cc.Sprite(bundles.simple.frames.lock_png);
    },

    createItem: function (index) {
        var container = new cc.Node();
        container.setAnchorPoint(0.5, 0.5);

        container.progressBar = this.createProgressBar(index);
        container.icon = this.createIcon(index);

        container.setContentSize2(container.progressBar);

        container.progressBar.setPosition(container.width / 2, container.height / 2);
        container.icon.setPosition(container.width / 2, container.height / 2);

        container.addChild(container.progressBar);
        container.addChild(container.icon);

        if (index === cleverapps.meta.getLastLocationId()) {
            var comingSoonText = cleverapps.UI.generateTTFText("BackgroundsWindow.ComingSoonTitle", cleverapps.styles.FONTS.BACKGROUNDS_WINDOW_NAME_COMING_SOON);
            comingSoonText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
            comingSoonText.setPosition(container.width / 2, container.height / 2);
            comingSoonText.fitTo(container.width);
            container.addChild(comingSoonText);
        }

        return container;
    },

    createProgressBar: function (index) {
        var current = cleverapps.meta.getMainObject().locationId;
        var progressBar = new cleverapps.Spine(bundles.simple.jsons.progress_bar_animation);

        var animation = "idle completed";
        if (index === current) {
            animation = "idle_" + this.getCurrentAnimationNumber();
        }
        if (index > current) {
            animation = "idle closed";
        }

        progressBar.setAnimation(0, animation, false);
        return progressBar;
    },

    updateProgress: function (addedStars, callback) {
        var current = cleverapps.meta.getMainObject().locationId;

        var animationNumber = this.getCurrentAnimationNumber();

        var progressBar = this.items[current].item.progressBar;

        if (animationNumber === 0) {
            progressBar.setAnimation(0, "idle_" + animationNumber, false);
        } else {
            progressBar.setAnimationAndIdleAfter("animation_" + animationNumber, "idle_" + animationNumber);
        }

        progressBar.setCompleteListener(function () {
            cleverapps.focusManager.onFocusLostListenerPlacements();
            callback && callback();
        });
    },

    getCurrentAnimationNumber: function () {
        return Math.floor((cleverapps.meta.getMainObject().getCurrentPercent()) / 5) * 5;
    },

    changeCurrent: function (f) {
        var current = cleverapps.meta.getMainObject().locationId;

        var previousItem = this.items[current - 1];
        var nextItem = this.items[current + 1];
        var currentItem = this.items[current];

        if (current === this.items.length) {
            nextItem = false;
        }

        this.scroll.runAction(
            new cc.Sequence(
                new cc.CallFunc(function () {
                    this.scroll.scrollTo(previousItem, 1);
                }.bind(this)),
                new cc.DelayTime(1),

                new cc.CallFunc(function () {
                    var animation = new cleverapps.Spine(bundles.simple.jsons.circle_particles_animation);
                    animation.setAnimation(0, "animation", false);
                    animation.setPosition(this.width / 2, this.height / 2);
                    this.addChild(animation);
                    animation.setCompleteListenerRemove();

                    cleverapps.audio.playSound(bundles.main.urls.simple_next_stage);
                }.bind(this)),
                new cc.DelayTime(1.5),

                new cc.CallFunc(function () {
                    previousItem.item.runAction(
                        new cc.Sequence(
                            new cc.ScaleTo(0.23, 1.76),
                            new cc.ScaleTo(0.33, 1).easing(cc.easeInOut(3))
                        )
                    );
                    previousItem.item.progressBar.setAnimation(0, "completion animation", false);
                    this.starCountView.runAction(new cc.FadeOut(0.3));
                }.bind(this)),
                new cc.DelayTime(0.6),

                new cc.CallFunc(function () {
                    previousItem.item.setScale(1);
                    previousItem.item.cantScale = true;
                    previousItem.item.progressBar.setAnimation(0, "completed mark", false);
                }),
                new cc.DelayTime(0.2),

                new cc.CallFunc(function () {
                    if (nextItem) {
                        nextItem.item.icon.removeFromParent();
                        nextItem.item.icon = this.createIcon(current + 1);
                        nextItem.item.icon.setPosition(nextItem.item.width / 2, nextItem.item.height / 2);
                        nextItem.item.addChild(nextItem.item.icon);
                        cleverapps.UI.convertToGrayShape(nextItem.item.icon);
                    }
                    this.scroll.scrollTo(currentItem, 1.5);

                    var line = this.createLineBar(current);
                    previousItem.addChild(line);

                    if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
                        this.text.runAction(new cc.Spawn(
                            new cc.MoveBy(0.65, -cleverapps.styles.SimpleProgressAltView.text.dx, 0),
                            new cc.FadeOut(0.65)
                        ));

                        line.setPositionRound(cleverapps.styles.SimpleProgressAltView.line.vertical.next);
                        line.runAction(new cc.MoveBy(1.5, 0, cleverapps.styles.SimpleProgressAltView.line.vertical.dy));
                    } else {
                        this.text.replaceParentSamePlace(previousItem);
                        this.text.runAction(new cc.FadeOut(0.75));

                        line.setPositionRound(cleverapps.styles.SimpleProgressAltView.line.horizontal.next);
                        line.runAction(new cc.MoveBy(1.5, cleverapps.styles.SimpleProgressAltView.line.horizontal.dx, 0));
                    }
                }.bind(this)),
                new cc.DelayTime(0.5),

                new cc.CallFunc(function () {
                    if (currentItem.item) {
                        cleverapps.UI.convertToGrayShape(currentItem.item.icon, true);
                    }
                    currentItem.item.progressBar.setAnimation(0, "opening", false);
                }),
                new cc.DelayTime(0.2),

                new cc.CallFunc(function () {
                    var msg = "background_name_" + cleverapps.meta.getMainObject().locationId;
                    var text = cleverapps.UI.generateOnlyText(msg, cleverapps.styles.FONTS.BACKGROUND_NAME_TEXT);
                    text.fitTo(cleverapps.styles.SimpleProgressAltView.text.width);
                    text.setOpacity(0);

                    if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
                        text.setPositionRound(cleverapps.styles.SimpleProgressAltView.text.position);
                        this.addChild(text);

                        text.runAction(new cc.Spawn(
                            new cc.MoveBy(0, cleverapps.styles.SimpleProgressAltView.text.dx, 0),
                            new cc.MoveBy(0.65, -cleverapps.styles.SimpleProgressAltView.text.dx, 0),
                            new cc.FadeIn(0.65)
                        ));
                    } else {
                        text.setPositionRound(cleverapps.styles.SimpleProgressAltView.text.next);
                        currentItem.addChild(text);
                        text.runAction(new cc.FadeIn(0.8));
                    }
                }.bind(this)),
                new cc.DelayTime(0.8),

                new cc.CallFunc(function () {
                    previousItem.item.cantScale = false;
                    currentItem.item.progressBar.setAnimation(0, "current appear", false);
                    cleverapps.meta.trigger("changeStars", true);
                    this.starCountView.runAction(new cc.FadeIn(0.3));
                    this.starCountView.setPositionRound(cleverapps.styles.SimpleProgressAltView.starCount);
                }.bind(this)),
                new cc.DelayTime(1),

                new cc.CallFunc(function () {
                    cleverapps.audio.playSound(bundles.main.urls.simple_transition);
                    f();
                })
            )
        );
    }
});

ProgressView = SimpleProgressAltView;

cleverapps.UI.convertToGrayShape = cleverapps.extendFunc(cleverapps.UI.convertToGrayShape, function (node, restore) {
    node.setColor(cleverapps.styles.COLORS.DARK_GRAY);
    node.setOpacity(125);

    if (restore) {
        node.setColor(cleverapps.styles.COLORS.WHITE);
        node.setOpacity(255);
    }
});

cleverapps.styles.SimpleProgressAltView = {
    position: [
        {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 20 }
        }, {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -25 }
        }, {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -25 }
        }
    ],

    line: {
        overlap: -20,
        vertical: {
            height: 80,
            width: 40,
            next: {
                x: { align: "center" },
                y: { align: "top", dy: -100 }
            },
            dy: 100
        },
        horizontal: {
            height: 50,
            width: 100,
            next: {
                x: { align: "right", dx: -100 },
                y: { align: "center", dy: 1 }
            },
            dx: 100
        }
    },

    starCount: {
        x: { align: "center" },
        y: { align: "top", dy: 40 }
    },

    text: {
        width: 250,
        position: [{
            x: { align: "center" },
            y: { align: "bottom", dy: -90 }
        }, {
            x: { align: "center" },
            y: { align: "bottom", dy: 18 }
        }, {
            x: { align: "center" },
            y: { align: "bottom", dy: 18 }
        }],
        next: {
            x: { align: "center", dx: 1 },
            y: { align: "bottom", dy: -152 }
        },
        dx: 100
    },

    background: {
        position: {
            frame1: [
                {
                    x: { align: "center", dx: 1 },
                    y: { align: "center", dy: -5 }
                }, {
                    x: { align: "center", dx: 1 },
                    y: { align: "center", dy: 68 }
                }, {
                    x: { align: "center", dx: 1 },
                    y: { align: "center", dy: 68 }
                }
            ],
            frame2: [
                {
                    x: { align: "center", dx: 1 },
                    y: { align: "center", dy: -422 }
                }, {
                    x: { align: "center", dx: 1 },
                    y: { align: "center", dy: -267 }
                }, {
                    x: { align: "center", dx: 1 },
                    y: { align: "center", dy: -267 }
                }
            ]
        },
        size: {
            frame1: [
                {
                    width: 365,
                    height: 795
                }, {
                    width: 1200,
                    height: 600
                }, {
                    width: 1200,
                    height: 600
                }
            ],
            frame2: [
                {
                    width: 290,
                    height: 40
                }, {
                    width: 434,
                    height: 70
                }, {
                    width: 434,
                    height: 70
                }
            ]
        }
    },

    scroll: {
        position: [{
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -25 }
        }, {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 10 }
        }, {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 10 }
        }
        ],
        pagesCount: 3,
        zoom: 1.65,
        vertical: {
            scale: 0.6,
            height: 1100,
            width: 475
        },

        horizontal: {
            height: 600,
            width: 1200
        }
    }
};
