/**
 * Created by Mikhail Menshenin on 17.06.2024
 */

delete Forces.FOG_EXPLORE;
Forces.YATZY_COMBO = {
    id: 13,
    text: "tutorial_yatzy_6"
};

var ComboTutorialScenario = {
    name: "YatzyCombo",
    priority: 10,

    context: Object.assign({}, TutorialContext, {
        find3DicesWithSameScore: function () {
            var yatzy = Game.currentGame;
            var diceCount = yatzy.dices.reduce(function (acc, dice) {
                if (!dice.score) {
                    return acc;
                }

                if (!acc[dice.score]) {
                    acc[dice.score] = [];
                }

                acc[dice.score].push(dice);
                return acc;
            }, {});

            for (var score in diceCount) {
                if (diceCount[score].length === 3) {
                    return diceCount[score];
                }
            }
        },

        isAvailable: function () {
            if (cleverapps.forces.isShown(Forces.YATZY_COMBO.id)) {
                return false;
            }

            var yatzy = Game.currentGame;
            if (yatzy.rolls < 2) {
                return false;
            }

            if (yatzy.fullRolls()) {
                return false;
            }

            if (!levels.user.checkAvailable(cleverapps.Availables.YATZY_COMBO)) {
                return false;
            }

            var yatzySlot = yatzy.slots[Slot.YATZY];
            if (yatzySlot.used) {
                return false;
            }
            return this.find3DicesWithSameScore();
        },

        onStartScenario: function () {
            var yatzy = Game.currentGame;
            this.selectedDices = this.find3DicesWithSameScore();
            this.selectedScore = this.selectedDices[0].score;
            for (var i = 0; i < yatzy.dices.length; i++) {
                var dice = yatzy.dices[i];
                if (!this.selectedDices.includes(dice)) {
                    this.selectedDices.push(dice);
                }
            }
        },

        prepareData: function (data) {
            var yatzy = Game.currentGame;

            if (!data) {
                return;
            }

            if (data.rollFirst) {
                var changed = false;
                data.roll = yatzy.dices.map(function (dice) {
                    if (dice.score !== this.selectedScore && !changed) {
                        changed = true;
                        return this.selectedScore;
                    }

                    return dice.score;
                }.bind(this));
            }

            if (data.rollSecond) {
                data.roll = yatzy.dices.map(function () {
                    return this.selectedScore;
                }.bind(this));
            }

            if (data.chooseDice !== undefined) {
                var dice = this.selectedDices[data.chooseDice];
                data.dice = dice.id;
            }

            return data;
        }
    }),

    steps: [
        {
            chooseDice: 0,
            force: {
                text: "tutorial_yatzy_0"
            }
        },
        {
            chooseDice: 1,
            force: {
                text: "tutorial_yatzy_1"
            }
        },
        {
            chooseDice: 2
        },
        {
            rollFirst: true,
            force: {
                text: "tutorial_yatzy_3"
            }
        },
        {
            chooseDice: 3,
            force: {
                text: "tutorial_yatzy_4",
                delay: 1
            }
        },
        {
            rollSecond: true
        },
        {
            slot: Slot.YATZY,
            force: Forces.YATZY_COMBO
        }
    ]
};
