/**
 * Created by mac on 9/16/23
 */

var Tutorial = function () {
    this.active = undefined;
    this.stepNo = undefined;
    this.waiting = false;

    this.scenarios = {};
};

Tutorial.prototype.isActive = function () {
    return this.active !== undefined;
};

Tutorial.prototype.getInfo = function () {
    var info = this.active ? {
        name: this.active.name,
        stepNo: this.stepNo
    } : undefined;
    return info;
};

Tutorial.prototype.onFinishCallback = function () { };

Tutorial.prototype.run = function (name, save, f) {
    var scenario = this.scenarios[name];
    if (!scenario) {
        return;
    }

    save = save || {};
    this.active = new TutorialScenario(scenario);
    this.stepNo = (save.stepNo || 0) - 1;

    this.startNextStep();
    this.onFinishCallback = f || function () { };
};

Tutorial.prototype.startNextStep = function () {
    this.stepNo++;

    var data = this.step();

    if (!data) {
        this.waitFinishStep();
        return;
    }

    if (data.force) {
        var element = this.active.getForceElement(this.stepNo);
        var options = this.active.getForceOptions(this.stepNo);
        cleverapps.forces.create(element, data.force, options);
    }

    this.active.onStartStep(this.stepNo);

    this.save();
};

Tutorial.prototype.waitFinishStep = function () {
    if (!this.isActive()) {
        return;
    }

    if (cleverapps.forces.isRunningForce()) {
        this.cleanForce();
    }

    this.active.onFinishStep(this.stepNo);

    this.waiting = true;
    if (!Game.currentGame.counter.isActive()) {
        this.finishStep();
    }
};

Tutorial.prototype.finishStep = function () {
    if (!this.waiting) {
        return;
    }
    this.waiting = false;

    if (cleverapps.forces.getRunningForce()) {
        cleverapps.forces.closeRunningForce();
    }

    if (this.active.isLastStep(this.stepNo)) {
        this.active.onFinishScenario();
        this.active = undefined;

        this.onFinishCallback();
    } else {
        this.startNextStep();
    }
};

Tutorial.prototype.cleanForce = function () {
    var force = cleverapps.forces.getRunningForce();

    if (force.dialogue) {
        force.dialogue.removeFromParent();
        force.dialogue = undefined;
    }

    if (force.pointer) {
        PointerView.remove(force.pointer);
        force.pointer = undefined;
    }

    if (force.finger) {
        FingerView.remove(force.finger);
        force.finger = undefined;
    }

    cleverapps.focusManager.setEventNodes([]);
};

Tutorial.prototype.step = function () {
    return this.active.step(this.stepNo);
};

Tutorial.prototype.save = function () {
    if (Game.currentGame.introPlaying) {
        if (cleverapps.config.debugMode) {
            cleverapps.snapshots.extractData(function (data) {
                this.introData = data;
            }.bind(Game.currentGame));
        }
    }
};

Tutorial.prototype.register = function (scenario) {
    var name = scenario.name;

    if (!name) {
        var message = "can't register tutorial scenario without name";
        if (cleverapps.config.debugMode) {
            throw message;
        }
        cleverapps.throwAsync(message);
        return;
    }

    this.scenarios[name] = scenario;
};

Tutorial.prototype.processStage = function () {
    if (this.isActive()) {
        this.finishStep();
        return;
    }

    var scenarios = Object.values(this.scenarios).sort(function (a, b) {
        return (a.priority || Tutorial.MAX_PRIORITY) - (b.priority || Tutorial.MAX_PRIORITY);
    });

    var availableScenario = scenarios.find(function (scenario) {
        var context = scenario.context || {};
        return context.isAvailable && context.isAvailable();
    });

    if (availableScenario) {
        cleverapps.focusManager.display({
            focus: availableScenario.name,
            control: [],
            actions: [
                function (f) {
                    this.run(availableScenario.name, undefined, f);
                }.bind(this)
            ]
        });
    }
};

Tutorial.MAX_PRIORITY = Number.MAX_SAFE_INTEGER;
