/**
 * Created by mac on 2/28/23
 */

var YatzyScene = GameSceneBase.extend({
    onSceneLoaded: function (options) {
        this.infoPanel = new ResultsBarView();
        this._super(options);

        this.exclamationView._setGlobalZOrder(true);

        this.container = new cc.Node();
        this.container.setAnchorPoint(0.5, 0.5);

        this.column1 = new cc.Node();
        this.column2 = new cc.Node();

        this.container.addChild(this.column1);
        this.container.addChild(this.column2);

        this.resultsBar = this.infoPanel;
        this.resultsBar.init(this.game);
        this.container.addChild(this.resultsBar);

        this.yatzyField = new YatzyFieldView(this.game);
        this.container.addChild(this.yatzyField);

        this.dicesView = new DicesView(this.game.dices);
        this.container.addChild(this.dicesView);

        this.rollButton = new RollButtonView(this.game);
        this.container.addChild(this.rollButton);

        this.addChild(this.container);

        this.updateSize();
        this.setupChildren();
    },

    updateSize: function () {
        this._super();

        if (!this.container) {
            return;
        }

        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            this.container.setContentSize2(cleverapps.styles.YatzyScene.scene.vertical);
        } else {
            this.container.setContentSize2(cleverapps.styles.YatzyScene.scene.horizontal);
            this.column1.setContentSize2(this.container.width / 2, this.container.height);
            this.column2.setContentSize2(this.container.width / 2, this.container.height);
        }

        this.container.setPosition(this.width / 2, this.height / 2);
    },

    scaleGameField: function () {
        if (!this.container) {
            return;
        }
        cleverapps.UI.inflateToBoundaries(this.container, [this.upMenuContainer], {
            padding: cleverapps.styles.YatzyScene.scene.padding
        });
    },

    setupChildren: function () {
        this._super();

        if (!this.container) {
            return;
        }

        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            this.resultsBar.replaceParentSamePlace(this.container);
            this.yatzyField.replaceParentSamePlace(this.container);
            this.dicesView.replaceParentSamePlace(this.container);
            this.rollButton.replaceParentSamePlace(this.container);

            cleverapps.UI.fitToBox(this.yatzyField, this.container);
            cleverapps.UI.fitToBox(this.dicesView, this.container);
            cleverapps.UI.fitToBox(this.resultsBar, this.container);

            this.resultsBar.setPositionRound(cleverapps.styles.YatzyScene.results.vertical);
            this.yatzyField.setPositionRound(cleverapps.styles.YatzyScene.field.vertical);
            this.dicesView.setPositionRound(cleverapps.styles.YatzyScene.dices.vertical);
            this.rollButton.setPositionRound(cleverapps.styles.YatzyScene.buttons.vertical);
        } else {
            this.column1.setPositionRound(0, 0);
            this.column2.setPositionRound(this.container.width / 2, 0);

            this.resultsBar.replaceParentSamePlace(this.column2);
            this.yatzyField.replaceParentSamePlace(this.column1);
            this.dicesView.replaceParentSamePlace(this.column2);
            this.rollButton.replaceParentSamePlace(this.column2);

            this.resultsBar.setPositionRound(cleverapps.styles.YatzyScene.results.horizontal);
            this.yatzyField.setPositionRound(cleverapps.styles.YatzyScene.field.horizontal);
            cleverapps.UI.fitToBox(this.yatzyField, this.column1);
            cleverapps.UI.fitToBox(this.dicesView, this.column2);
            cleverapps.UI.fitToBox(this.resultsBar, this.column2);

            this.dicesView.setPositionRound(cleverapps.styles.YatzyScene.dices.horizontal);
            this.rollButton.setPositionRound(cleverapps.styles.YatzyScene.buttons.horizontal);
        }
    },

    changeGameMode: function () {
        this.resultsBar.removeFromParent();
        this.yatzyField.removeFromParent();

        this.resultsBar = new ResultsBarView(this.game.score);
        this.container.addChild(this.resultsBar);

        this.yatzyField = new YatzyFieldView(this.game);
        this.container.addChild(this.yatzyField);

        this.updateSize();
        this.setupChildren();
    }
});

GameScene = YatzyScene;

cleverapps.styles.YatzyScene = {
    scene: {
        horizontal: {
            width: 2050,
            height: 1170
        },
        vertical: {
            width: 550,
            height: 1000
        },
        padding: {
            top: 20,
            bottom: 20,
            right: 20,
            left: 20
        }
    },

    results: {
        horizontal: {
            x: { align: "center" },
            y: { align: "top", dy: -15 }
        },
        vertical: {
            x: { align: "center" },
            y: { align: "top", dy: 25 }
        }
    },

    field: {
        horizontal: {
            x: { align: "center" },
            y: { align: "center", dy: 0 }
        },
        vertical: {
            x: { align: "center" },
            y: { align: "center", dy: 95 }
        }
    },

    dices: {
        horizontal: {
            x: { align: "center" },
            y: { align: "center", dy: -25 }
        },
        vertical: {
            x: { align: "center" },
            y: { align: "bottom", dy: 105 }
        }
    },

    buttons: {
        horizontal: {
            x: { align: "center" },
            y: { align: "bottom", dy: 20 }
        },
        vertical: {
            x: { align: "center" },
            y: { align: "bottom", dy: -5 }
        }
    }
};