/**
 * Created by Andrey Popov on 30.03.2023
 */

var ScoreView = cc.Node.extend({
    ctor: function (score, targetScore) {
        this._super();

        this.targetScore = targetScore;

        this.setAnchorPoint(0.5, 0.5);

        var scoreText = score.getPoints();
        if (this.targetScore) {
            scoreText = scoreText + "/" + this.targetScore;
        }

        this.scoreView = cleverapps.UI.generateImageText(scoreText, cleverapps.styles.FONTS.SCORE_TEXT);
        this.scoreView.fitTo(cleverapps.styles.UI.ScoreView.maxWidth);
        this.addChild(this.scoreView);
        this.setPositionRound(cleverapps.styles.UI.ScoreView);

        score.on("onChange", this.updateScore.bind(this), this);
    },

    updateScore: function (points, isBigValue) {
        this.scoreView.countToAnimation(0.4, points, this.targetScore);
        this.scoreView.fitTo(cleverapps.styles.UI.ScoreView.maxWidth);

        if (isBigValue) {
            this.scoreView.runAction(
                new cc.Sequence(
                    new cc.ScaleTo(0.3, 1.2),
                    new cc.ScaleTo(0.1, 1.0)
                )
            );
        }
    }

});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    SCORE_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.WHITE
    }
});

cleverapps.styles.UI.ScoreView = {
    x: { align: "center" },
    y: { align: "center" },

    maxWidth: 350
};