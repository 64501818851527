/**
* Created by Mikhail Menshenin on 23.03.2024
*/

var MedalUnlockedWindow = CleverappsWindow.extend({
    onWindowLoaded: function (level) {
        var styles = cleverapps.styles.MedalUnlockedWindow;

        var content = this.createContent(level, styles);

        this._super({
            name: "MedalUnlockedWindow",
            title: "MedalUnlockedWindow.Title",
            styles: styles,
            content: content,
            shareId: "medal_unlocked",
            fireworks: true,
            closeButtonDelay: 0.5
        });

        cleverapps.audio.playSound(bundles.main.urls.collections_unlocked);
    },

    createContent: function (level, styles) {
        var medalName = this.medalName = cleverapps.UI.generateOnlyText("MedalCollection.MedalName" + level, cleverapps.styles.FONTS.MEDAL_UNLOCKED_TEXT);

        var medal = this.medal = new MedalShineView(level, {
            scale: 2,
            raysDelay: 0.3,
            starsDelay: 0.8
        });

        var medalDescription = this.medalDescription = cleverapps.UI.generateOnlyText("MedalCollection.MedalDesc" + level, cleverapps.styles.FONTS.MEDAL_UNLOCKED_TEXT);
        medalDescription.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        medalDescription.setFontSize(48);

        var content = new cleverapps.Layout([medalName, medal, medalDescription], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });

        return content;
    },

    onShow: function () {
        this.medalName.runAction(this.createTextAnimation());
        this.medalDescription.runAction(this.createTextAnimation());

        this.medal.medalIcon.setOpacity(0);
        this.medal.medalIcon.setScale(0.3);
        this.medal.medalIcon.runAction(
            cc.spawn(
                new cc.FadeIn(0.5),
                cleverapps.UI.animateScale({
                    scale: 2,
                    duration: 0.8,
                    overScaling: 1.1
                })
            )
        );

        this._super();
    },

    getPerson: function () {
        return {
            role: "hero",
            emotion: "happy"
        };
    },

    createTextAnimation: function () {
        var styles = cleverapps.styles.MedalUnlockedWindow;

        return new cc.Sequence(
            new cc.FadeOut(0),
            new cc.DelayTime(1),
            new cc.MoveBy(0, cc.p(0, -styles.text.offset)),
            new cc.Spawn(
                new cc.MoveBy(1, cc.p(0, styles.text.offset)),
                new cc.FadeIn(1)
            )
        );
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    MEDAL_UNLOCKED_TEXT: {
        size: 64,
        color: cleverapps.styles.COLORS.WHITE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_INVERSED_SHADOW
    }
});

cleverapps.styles.MedalUnlockedWindow = {
    titleMargin: 60,
    margin: 160,

    windowShowUpAnimation: {
        name: "instant"
    },

    noBackground: true,

    text: {
        offset: 10
    }
};