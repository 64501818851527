/**
 * Created by Slava on 11.04.2024
 */

VictoryWindow.prototype.onWindowLoaded = cleverapps.extendFunc(VictoryWindow.prototype.onWindowLoaded, function () {
    var rewardsOrder = ["soft", "simpleStar", "medals"];
    var rewards = [];
    rewardsOrder.forEach(function (type) {
        this.rewards.rewards.forEach(function (reward) {
            if (reward.type === type) {
                rewards.push(reward);
            }
        });
    }.bind(this));
    this.rewards.rewards = rewards;

    this._super();

    this.windowTitle.adjustPosition = function () {
        this.setPositionRound(cleverapps.styles.WindowTitle.Types.nobg_window.position);
    };

    this.windowTitle.text.setFont(cleverapps.styles.FONTS.VICTORY_TEXT);
});

VictoryWindow.prototype.listComponents = cleverapps.extendFunc(VictoryWindow.prototype.listComponents, function () {
    var data = [];
    var styles = cleverapps.styles.VictoryWindow.resultScore;

    var scoreBlock = new cc.Node();
    scoreBlock.setContentSize(styles.width, styles.height);
    scoreBlock.setAnchorPoint(0.5, 0.5);

    var score = Game.currentGame.score.getPoints();
    var resultText = cleverapps.UI.generateOnlyText("ResultsBar.YourScore", cleverapps.styles.FONTS.SCORE_TEXT_VICTORY_WINDOW);
    var resultScore = new cleverapps.UI.ImageFont(score, cleverapps.styles.FONTS.SCORE_RESULT_VICTORY_WINDOW);

    resultText.setPositionRound(styles.text);
    resultScore.setPositionRound(styles.score);

    scoreBlock.addChild(resultText);
    scoreBlock.addChild(resultScore);

    var scoreBlockItems = [resultText, resultScore];

    scoreBlock.beforeWinClose = function () {
        scoreBlockItems.forEach(function (item) {
            item.runAction(new cc.FadeOut(0.1));
        });
    };

    scoreBlock.showUp = function () {
        scoreBlockItems.forEach(function (item) {
            item.setCascadeOpacityEnabledRecursively(true);
            item.setOpacity(0);
            item.setPositionRound(item.x, item.y - styles.startOffsetY);

            item.runAction(
                new cc.Spawn(
                    new cc.MoveBy(0.4, 0, styles.startOffsetY).easing(cc.easeOut(2)),
                    new cc.FadeIn(0.4).easing(cc.easeIn(2))
                )
            );
        });
    };

    data.push(scoreBlock);

    data = data.concat(this._super());

    return data;
});

VictoryWindow.prototype.createRewards = cleverapps.extendFunc(VictoryWindow.prototype.createRewards, function () {
    var styles = cleverapps.styles.VictoryWindow.rewards;

    var columns = cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? 4 : 5;
    var rewardsList = this.rewardsList = new RewardsListComponent(this.rewards, {
        columns: columns,
        iconWrap: styles.iconWrap,
        textMargin: styles.textMargin,
        font: cleverapps.styles.FONTS.REWARDWINDOW_VALUE_TEXT,
        noShowControls: false,
        event: cleverapps.EVENTS.EARN.LEVEL_REWARD,
        shine: styles.shine,
        filterCustomAnimation: true
    });
    rewardsList.setLocalZOrder(2);
    return rewardsList;
});

VictoryWindow.prototype.inflateWindow = cleverapps.extendFunc(VictoryWindow.prototype.inflateWindow, function () {
    this._super();
    this.wrapper.setPositionRound(cleverapps.styles.VictoryWindow.wrapper.position);
});

cleverapps.whenAllInitialized(function () {
    if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
        cleverapps.overrideStyles(cleverapps.styles.VictoryWindow, {
            margin: 150,
            padding: {
                top: 100,
                bottom: -100
            }
        });
    }
});
