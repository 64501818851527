/**
 * Created by Mikhail Menshenin on 23.06.2024
 */

var TournamentScoreView = CloversView.extend({
    ctor: function () {
        this._super();

        this.withinInfoPanel = true;
        this.lastShownValue = this.getValue();

        this.shineAnimation = new cleverapps.Spine(bundles.yatzy.jsons.clover_icon_shine_json);
        this.shineAnimation.setPositionRound({
            x: { align: "center" },
            y: { align: "center", dy: 2 }
        });
        this.icon.addChild(this.shineAnimation);
        this.shineAnimation.setVisible(false);

        Game.currentGame.on("rewardClover", this.createListener(this.runShineAnimation.bind(this)));
    },

    runShineAnimation: function () {
        this.shineAnimation.setVisible(true);
        this.shineAnimation.setCompleteListener(function () {
            this.shineAnimation.setVisible(false);
        }.bind(this));
        this.shineAnimation.setAnimation(0, "animation", false);
    },

    runCollectAnimation: function () {
        var newValue = this.getValue();
        var delta = newValue - this.lastShownValue;

        this.stopAllActions();
        this.runAction(
            cc.sequence(
                cc.delayTime(0.5),
                cc.callFunc(function () {
                    this.text.setString(this.getTextString());
                    this.lastShownValue = newValue;
                }.bind(this)),
                AnimationsLibrary.animateDelta(delta, {
                    target: this.text,
                    x: this.text.width / 2,
                    y: -this.text.height / 2,
                    dy: this.text.height / 2,
                    duration: 0.6,
                    font: cleverapps.styles.FONTS.CLOVERS_TEXT
                })
            )
        );
    },

    getValue: function () {
        return Game.currentGame.rewards[GameBase.REWARD_SECONDARY];
    }
});

CloversView = TournamentScoreView;