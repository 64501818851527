/**
 * Created by Mikhail Menshenin on 2/29/24
 */

var MedalFieldView = cleverapps.GridLayout.extend({
    ctor: function (game) {
        this.game = game;

        var styles = cleverapps.styles.MedalFieldView;

        this.createCells(styles);

        this._super(this.cells.map(function (cell) {
            return cell.bg;
        }), {
            columns: MedalFieldView.FIELD_COLUMN_COUNT,
            margin: styles.margin,
            padding: styles.padding
        });

        this.createMedals();

        game.on("spawn", this.spawnAnimation.bind(this), this);
        game.on("select", this.selectAnimation.bind(this), this);
        game.on("unselect", this.removeSelect.bind(this), this);
        game.on("drop", this.dropAnimation.bind(this), this);
        game.on("merge", this.mergeAnimation.bind(this), this);
    },

    createCells: function (styles) {
        this.cells = this.game.createCells().map(function (cell) {
            cell.bg = cleverapps.UI.createScale9Sprite(bundles.medalcollection.frames.cell_bg_png);
            cell.bg.setContentSize(styles.cell);
            return cell;
        });
    },

    createMedals: function () {
        this.cells.forEach(function (cell) {
            var medal = this.game.getMedal(cell.x, cell.y);

            if (medal && medal.level) {
                this.createMedal(medal.level, cell);
            }
        }.bind(this));
    },

    createMedal: function (level, cellPos) {
        var cell = this.cells.find(function (cell) {
            return cell.x === cellPos.x && cell.y === cellPos.y;
        });

        if (!cell) {
            return;
        }

        var medal = cell.medal = new MedalItemView(this, level);
        medal.setPositionRound({
            x: { align: "center" },
            y: { align: "center" }
        });
        cell.bg.addChild(medal);

        return medal;
    },

    getMedalCell: function (medal) {
        return this.cells.find(function (cell) {
            return cell.medal === medal;
        });
    },

    getNearestCell: function (medal) {
        var minDist = cc.pDistance(this.cells[0].bg.getPosition(), this.cells[1].bg.getPosition());
        var nearestCell = undefined;
        var medalPos = medal.parent.convertToWorldSpace(medal.getPosition());

        for (var i = 0; i < this.cells.length; i++) {
            var cell = this.cells[i];
            var cellPos = cell.bg.parent.convertToWorldSpace(cell.bg.getPosition());
            var dist = cc.pDistance(cellPos, medalPos);
            if (dist < minDist) {
                minDist = dist;
                nearestCell = cell;
            }
        }

        return nearestCell;
    },

    spawnAnimation: function (cellPos, cb) {
        var cell = this.cells.find(function (cell) {
            return cell.x === cellPos.x && cell.y === cellPos.y;
        });
        var data = this.game.getMedal(cell.x, cell.y);
        var medal = this.createMedal(data.level, cell);

        var spawnAnimation = new cleverapps.Spine(bundles.medalcollection.jsons.spawn_animation_json);
        spawnAnimation.setAnimation(0, "animation", false);
        spawnAnimation.setCompleteListenerRemove();
        spawnAnimation.setPosition(cell.bg.getPosition());
        this.addChild(spawnAnimation);

        medal.runAction(
            new cc.Sequence(
                cc.fadeIn(0.3),
                cc.delayTime(0.6),
                cc.callFunc(function () {
                    if (cb) {
                        cb();
                    }
                })
            )
        );
    },

    selectAnimation: function (cellPos) {
        var styles = cleverapps.styles.MedalFieldView;
        var cell = cellPos && this.cells.find(function (cell) {
            return cell.x === cellPos.x && cell.y === cellPos.y;
        });

        if (cell) {
            var selectAnimation = cell.select = new cleverapps.Spine(bundles.medalcollection.jsons.select_animation_json);
            selectAnimation.setAnimation(0, "animation", true);
            selectAnimation.setPositionRound(styles.select);
            cell.bg.addChild(selectAnimation);
        }
    },

    removeSelect: function () {
        this.cells.forEach(function (cell) {
            if (cell.select) {
                cell.select.removeFromParent();
                cell.select = undefined;
            }
        });
    },

    dropAnimation: function (cellFromPos, cellToPos) {
        var cellFrom = this.cells.find(function (cell) {
            return cell.x === cellFromPos.x && cell.y === cellFromPos.y;
        });
        var cellTo = cellToPos && this.cells.find(function (cell) {
            return cell.x === cellToPos.x && cell.y === cellToPos.y;
        });
        var targetNode = cellTo ? cellTo.bg : cellFrom.bg;
        var medal = cellFrom.medal;
        var target = medal.parent.convertToNodeSpace(targetNode.parent.convertToWorldSpace(targetNode.getPosition()));

        if (cellTo) {
            cellFrom.medal = undefined;
            cellTo.medal = medal;
        }

        medal.runAction(
            new cc.Sequence(
                cc.moveTo(0.2, target),
                new cc.CallFunc(function () {
                    medal.replaceParentSamePlace(targetNode);
                    medal.setPositionRound({
                        x: { align: "center" },
                        y: { align: "center" }
                    });
                    medal.showLevelText();
                })
            )
        );
    },

    mergeAnimation: function (cellFromPos, cellToPos, level, cb) {
        var cellFrom = this.cells.find(function (cell) {
            return cell.x === cellFromPos.x && cell.y === cellFromPos.y;
        });
        var cellTo = this.cells.find(function (cell) {
            return cell.x === cellToPos.x && cell.y === cellToPos.y;
        });
        var medalFrom = cellFrom.medal;
        var medalTo = cellTo.medal;
        var targetNode = cellTo.bg;
        var target = medalFrom.parent.convertToNodeSpace(targetNode.parent.convertToWorldSpace(targetNode.getPosition()));

        var mergeAnimation = new cleverapps.Spine(bundles.medalcollection.jsons.merge_animation_json);
        mergeAnimation.setPosition(cellTo.bg.getPosition());
        mergeAnimation.setCompleteListenerRemove();
        this.addChild(mergeAnimation);
        mergeAnimation.setVisible(false);

        medalFrom.runAction(
            cc.sequence(
                cc.place(target),
                cc.moveBy(0.1, -100, 0).easing(cc.easeOut(3)),
                cc.callFunc(function () {
                    mergeAnimation.setVisible(true);
                    mergeAnimation.setLocalZOrder(100);
                    mergeAnimation.setAnimation(0, "animation", false);
                }),
                cc.moveBy(0.1, 100, 0).easing(cc.easeIn(3)),
                cc.hide(),
                cc.callFunc(function () {
                    this.createMedal(level, cellTo);

                    var newMedal = cellTo.medal;
                    newMedal.runAction(cleverapps.UI.animateScale({
                        scale: 1,
                        duration: 0.3,
                        overScaling: 1.2
                    }));
                }.bind(this)),
                cc.delayTime(1),
                cc.callFunc(function () {
                    if (cb) {
                        cb();
                    }
                }),
                cc.removeSelf()
            )
        );

        medalTo.placeOnTop();
        medalTo.setLocalZOrder(99);
        medalTo.hideLevelText();
        medalTo.runAction(
            new cc.Sequence(
                cc.moveBy(0.1, 100, 0).easing(cc.easeOut(3)),
                cc.moveBy(0.1, -100, 0).easing(cc.easeIn(3)),
                new cc.RemoveSelf()
            )
        );
    }
});

MedalFieldView.FIELD_COLUMN_COUNT = 4;

cleverapps.styles.MedalFieldView = {
    margin: {
        x: 16,
        y: 16
    },

    padding: {
        top: 40,
        bottom: 48
    },

    select: {
        x: { align: "center" },
        y: { align: "center" }
    },

    cell: {
        width: 158,
        height: 158
    }
};
