/**
 * Created by Slava on 24.07.2024
 */

var ProlongationTutorialScenario = {
    name: "Prolongation",
    priority: 10,

    context: Object.assign({}, TutorialContext, {
        hasTwoPairs: function (diceCount) {
            return diceCount.filter(function (count) {
                return count === 2;
            }).length === 2;
        },

        hasStraight: function (diceCount) {
            var start;
            var count = 1;
            diceCount.forEach(function (dice, id) {
                if (diceCount[id - 1] > 0) {
                    count++;
                    if (!start) {
                        start = id;
                    }
                } else if (count !== 4) {
                    count = 1;
                    start = id;
                }
            });

            return {
                count: count,
                start: start
            };
        },

        getSmallStraightStart: function (diceCount) {
            var start = 0;
            var length = 0;
            for (var index = 0; index < diceCount.length; index++) {
                var count = diceCount[index];
                if (length) {
                    if (count) {
                        length++;
                    } else if (length > 3) {
                        break;
                    } else {
                        start = 0;
                        length = 0;
                    }
                } else if (count) {
                    start = index;
                    length = 1;
                }
            }

            return length === 4 ? start : 0;
        },

        getRoll: function (score) {
            var yatzy = Game.currentGame;
            return yatzy.dices.map(function (dice, index) {
                if (this.selectedDices.includes(index)) {
                    return dice.score;
                }

                return score;
            }.bind(this));
        },

        setYatzy: function () {
            var yatzy = Game.currentGame;
            var diceCount = yatzy.getDiceCount();
            this.selectedDices = yatzy.dices.map(function (dice, index) {
                return diceCount[dice.score] >= 3 ? index : undefined;
            }).filter(function (index) {
                return index !== undefined;
            });

            var score = yatzy.dices[this.selectedDices[0]].score;
            this.next = this.getRoll(score);

            this.slot = Slot.YATZY;
        },

        setFullHouse: function () {
            var yatzy = Game.currentGame;
            var diceCount = yatzy.getDiceCount();
            this.selectedDices = yatzy.dices.map(function (dice, index) {
                return diceCount[dice.score] === 2 ? index : undefined;
            }).filter(function (index) {
                return index !== undefined;
            });

            var score = yatzy.dices[this.selectedDices[0]].score;
            this.next = this.getRoll(score);

            this.slot = Slot.FULL_HOUSE;
        },

        setStraight: function (start) {
            var yatzy = Game.currentGame;
            var used = [];
            this.selectedDices = yatzy.dices.reduce(function (selected, dice) {
                var score = dice.score;
                var id = dice.id;

                if (score >= start && score < start + 4 && !used[score]) {
                    selected.push(id);
                    used[score] = true;
                }

                return selected;
            }, []);

            var score = start < 3 ? start + 4 : 2;
            this.next = this.getRoll(score);
            this.slot = Slot.LARGE_STRAIGHT;
        },

        checkCombination: function () {
            var yatzy = Game.currentGame;
            var diceCount = yatzy.getDiceCount();

            var slot = yatzy.slots[Slot.YATZY];
            if (!slot.used && slot.score === 0) {
                var maxCount = Math.max.apply(null, diceCount.filter(Boolean));

                if (maxCount === 3 || maxCount === 4) {
                    this.setYatzy();

                    return true;
                }
            }

            slot = yatzy.slots[Slot.FULL_HOUSE];
            if (!slot.used && this.hasTwoPairs(yatzy.getDiceCount())) {
                this.setFullHouse();

                return true;
            }

            slot = yatzy.slots[Slot.LARGE_STRAIGHT];
            if (!slot.used && slot.score === 0) {
                var start = this.getSmallStraightStart(yatzy.getDiceCount());
                if (start) {
                    this.setStraight(start);

                    return true;
                }
            }

            return false;
        },

        isAvailable: function () {
            if (cleverapps.forces.isShown(Forces.FREE_PROLONGATION_FORCE.id)) {
                return false;
            }

            var yatzy = Game.currentGame;
            if (!yatzy.noRollsLeft()) {
                return false;
            }

            if (!levels.user.checkAvailable(cleverapps.Availables.FREE_PROLONGATION_FORCE)) {
                return false;
            }

            return this.checkCombination();
        },

        onStartScenario: function () {
            var yatzy = Game.currentGame;
            yatzy.resetDices();
            yatzy.prolongationScenario = true;
            yatzy.trigger("changeRolls");
        },

        prepareData: function (data) {
            var yatzy = Game.currentGame;

            if (!data) {
                return;
            }

            if (data.roll) {
                data.roll = this.next;
            }

            if (data.slot) {
                data.slot = this.slot;
            }

            if (data.chooseDice !== undefined) {
                var dice = yatzy.dices[this.selectedDices[data.chooseDice]];
                if (dice) {
                    data.dice = dice.id;
                } else {
                    data = undefined;
                }
            }

            return data;
        }
    }),

    steps: [
        {
            chooseDice: 0,
            force: {
                text: "tutorial_prolongation_0"
            }
        },
        {
            chooseDice: 1
        },
        {
            chooseDice: 2
        },
        {
            chooseDice: 3
        },
        {
            roll: true,
            force: {
                text: "tutorial_prolongation_1"
            }
        },
        {
            slot: true,
            force: {
                id: Forces.FREE_PROLONGATION_FORCE.id,
                text: "tutorial_prolongation_2"
            }
        }
    ]
};