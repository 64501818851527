/**
 * Created by Mikhail Menshenin on 30.08.2024
 */

AnimationsLibrary.countTo = function (node, newValue, options) {
    var duration = options.duration || 1;
    var currentValue, total, divider;
    var currentText = node.getString && node.getString();
    var numbers = currentText.match(/\d+/g);
    currentValue = options.from !== undefined ? options.from : parseInt(numbers[0]);
    total = numbers && parseInt(numbers[1]) || 0;
    divider = currentText.replace(/\d+/g, "");

    var FPS = 30;
    var delta = newValue - currentValue;
    var steps = Math.min(Math.ceil(FPS * duration), Math.abs(delta));
    var increment = delta / steps;
    var stepDuration = duration / steps;
    var getString = typeof options.getString === "function" ? options.getString : function (value, divider, total) {
        return total ? value + divider + total : value;
    };
    var updateValueAction = function (value) {
        return new cc.Sequence(
            new cc.CallFunc(function () {
                node.setString(getString(value, divider, total));
                if (options.sound) {
                    cleverapps.audio.playSound(options.sound);
                }
            }),
            new cc.DelayTime(stepDuration)
        );
    };
    var actions = [];
    for (var i = 0; i < steps; i++) {
        currentValue += increment;
        actions.push(updateValueAction(Math.ceil(currentValue)));
    }

    return actions.length > 1 ? new cc.Sequence(actions) : actions[0];
};

AnimationsLibrary.spawn = function (node, options) {
    options = options || {};
    var delay = options.delay || 0;
    var duration = options.duration || 0.3;
    var callback = options.callback || function () { };
    var baseScale = node.scale;
    return new cc.TargetedAction(node, new cc.Sequence(
        new cc.CallFunc(function () {
            node.setVisible(false);
            node.setScale(0.3 * baseScale);
            node.setOpacity(0.3);
            node.setCascadeOpacityEnabledRecursively(true);
        }),
        new cc.DelayTime(delay),
        new cc.Show(),
        new cc.Spawn(
            new cc.CallFunc(function () {
                if (options.flash) {
                    var showUpSpine = new cleverapps.Spine(bundles.animations_library.jsons.show_up_json);
                    node.addChild(showUpSpine);
                    showUpSpine.setPositionRound({ align: "center" }, { align: "center" });
                    showUpSpine.setAnimation(0, "animation", false);
                    showUpSpine.setCompleteListenerRemove();
                }
            }),
            new cc.ScaleTo(duration, baseScale).easing(cc.easeBackOut(2)),
            new cc.FadeIn(0.15)
        ),
        new cc.CallFunc(function () {
            callback();
        })
    ));
};
