/**
 * Created by mac on 7/4/23
 */

var SlotView = cc.Node.extend({
    ctor: function (slot, isDouble) {
        this._super();

        this.slot = slot;

        this.isDouble = isDouble;

        var view = this.createSlot();
        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize(view.getContentSize());
        view.setPosition(this.width / 2, this.height / 2);
        this.addChild(view);

        slot.on("scored", this.hintScore.bind(this), this);
        slot.on("selected", this.animateSelection.bind(this), this);
        slot.on("collect", this.animateCollect.bind(this), this);
        slot.on("confirm", this.animateConfirmation.bind(this), this);
        slot.on("highlight", this.createListener(this.animateHighlight.bind(this)));

        var game = Game.currentGame;
        var currentLevel = game.level.getHumanReadableNumber();
        if (currentLevel === 1 && slot.score || currentLevel !== 1 && slot.score !== undefined) {
            this.hintScore();
        }
        if (slot.selected) {
            this.animateSelection();
        }
        if (slot.used) {
            this.animateConfirmation();
        }
    },

    createSlot: function () {
        var styles = cleverapps.styles.Slot;

        this.icon = new cc.Sprite(bundles.slots.frames.slot_bg);

        var icon = this.icon.icon = new cc.Sprite(bundles.slots.frames["slot_" + this.slot.id]);
        icon.setPosition(this.icon.width / 2, this.icon.height / 2);
        this.icon.addChild(icon);
        this.icon.setCascadeOpacityEnabled(true);

        var highlight = this.icon.highlight = cleverapps.UI.createScale9Sprite(bundles.slots.frames.choose);
        highlight.setContentSize(styles.highlight);
        highlight.setPositionRound(styles.highlight);
        this.icon.addChild(highlight, -1);
        highlight.setVisible(false);

        this.scoreBg = new cc.Sprite(bundles.slots.frames.score_slot_bg);

        this.resultText = new cleverapps.UI.ImageFont("", cleverapps.styles.FONTS.SLOT_SCORE_TEXT);
        this.resultText.setPositionRound(styles.resultBox.text.position);
        this.scoreBg.addChild(this.resultText);

        cleverapps.aims.registerTarget("slot" + this.slot.id, this.scoreBg);

        this.statusBox = new cc.Node();
        this.statusBox.setContentSize2(styles.statusBox);
        this.statusBox.setAnchorPoint(0.5, 0.5);

        if (this.isDouble) {
            this.statusBox.opponentResult = new cleverapps.UI.ImageFont("", cleverapps.styles.FONTS.SLOT_SCORE_TEXT_GRAY);
            this.statusBox.opponentResult.setPositionRound(styles.statusBox.position);
            this.statusBox.opponentResult.setOpacity(153);
            this.statusBox.addChild(this.statusBox.opponentResult);
        } else {
            this.statusBox.textDescription = cleverapps.UI.generateOnlyText("slot_" + this.slot.id, cleverapps.styles.FONTS.SLOT_TITLE);
            this.statusBox.textDescription.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
            this.statusBox.textDescription.setDimensions(styles.statusBox.width, 0);
            this.statusBox.textDescription.setPositionRound(styles.statusBox.position);
            this.statusBox.addChild(this.statusBox.textDescription);
        }

        var items = [this.icon, this.scoreBg, this.statusBox];

        var layout = new cleverapps.Layout(items, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });

        this.slotBg = cleverapps.UI.createScale9Sprite(bundles.slots.frames.choose);
        this.slotBg.setContentSize2(layout.width + styles.selected.padding.width, layout.height + styles.selected.padding.height);
        this.slotBg.setPositionRound(styles.selected.position);
        layout.addChild(this.slotBg);
        this.slotBg.setLocalZOrder(-1);
        this.slotBg.setVisible(false);

        this.clickHandler = cleverapps.UI.onClick(layout, this.pressed.bind(this), {
            interactiveScale: false
        });

        if (!this.slot.used) {
            var tooltipOptions = {
                content: this.createTooltipContent(),
                position: cleverapps.styles.UI.Tooltip.LOCATION_ABOVE
            };

            cleverapps.tooltipManager.create(this.icon, tooltipOptions);

            this.icon.tooltipHandler.remove();
            this.icon.tooltipHandler = cleverapps.UI.onClick(this.icon, function () {
                cleverapps.tooltipManager.onClick(this.icon, tooltipOptions);
            }.bind(this), {
                interactiveScale: false
            });
        }

        return layout;
    },

    createTooltipContent: function () {
        var tooltipNode = new cc.Node();
        tooltipNode.setCascadeOpacityEnabled(true);
        tooltipNode.setContentSize2(cleverapps.styles.UI.Tooltip.SIZE.medium.width, cleverapps.styles.UI.Tooltip.SIZE.medium.height - cleverapps.styles.UI.Tooltip.content.padding);
        tooltipNode.setAnchorPoint(0.5, 0.5);

        var description = cleverapps.UI.generateOnlyText(Messages.get("slotDescription_" + this.slot.id), cleverapps.styles.FONTS.TOOLTIP_TEXT);
        description.fitTo(tooltipNode.width);
        description.setPositionRound(cleverapps.styles.Slot.tooltip.firstLine.position);
        tooltipNode.addChild(description);

        var scoreText = cleverapps.UI.generateOnlyText(Messages.get("scoreHint_" + this.slot.id), cleverapps.styles.FONTS.TOOLTIP_TEXT_VIOLET);
        scoreText.fitTo(tooltipNode.width);
        scoreText.setPositionRound(cleverapps.styles.Slot.tooltip.secondLine.position);
        tooltipNode.addChild(scoreText);

        return tooltipNode;
    },

    hintScore: function () {
        var prev = this.resultText.string;
        if (this.isDouble && this.slot.opponentUsed) {
            this.statusBox.opponentResult.setString(this.slot.opponentScore);
        }

        if (Game.currentGame.hintSlots && !Game.currentGame.hintSlots.includes(this.slot.id)) {
            return;
        }

        if (prev === undefined || this.slot.score === 0 || this.slot.score === +prev || this.slot.used) {
            this.resultText.setString(this.slot.score);
            return;
        }

        var originalColor = this.resultText.color;
        this.resultText.runAction(new cc.Sequence(
            cleverapps.UI.animateScale({ duration: 10 / 30, scale: 0.8 }),
            new cc.CallFunc(function () {
                this.resultText.setString(this.slot.score);
                this.resultText.setColor(cleverapps.styles.COLORS.GREEN_SLOT);
            }.bind(this)),
            cleverapps.UI.animateScale({ duration: 5 / 30, scale: 1.2 }),
            new cc.CallFunc(function () {
                this.resultText.setColor(originalColor);
            }.bind(this)),
            cleverapps.UI.animateScale({ duration: 10 / 30, scale: 1 })
        ));
    },

    animateSelection: function () {
        this.slotBg.setVisible(true);

        this.scoreBg.setSpriteFrame(new cc.Sprite(bundles.slots.frames.slot_selected).getSpriteFrame());
    },

    animateCollect: function () {
        var animation = new cleverapps.Spine(bundles.slots.jsons.star_particles);
        animation.setAnimation(0, "animation", false);
        animation.setPositionRound(cleverapps.styles.Slot.resultBox.text.position);
        animation.setCompleteListenerRemove();
        this.scoreBg.addChild(animation);
    },

    animateConfirmation: function () {
        this.slotBg.setVisible(false);

        this.resultText.setPositionRound(cleverapps.styles.Slot.resultBox.text.position);
        this.resultText.replaceParentSamePlace(this);
        this.resultText.setFont(cleverapps.styles.FONTS.SLOT_SCORE_PLAYED_TEXT);
        this.resultText.setOpacity(153);

        this.scoreBg.setVisible(false);

        this.icon.setOpacity(153);
        this.icon.setColor(cleverapps.styles.COLORS.COLOR_GRAY);
        this.icon.icon.setColor(cleverapps.styles.COLORS.BLACK);
        this.icon.icon.setOpacity(100);

        if (this.statusBox.textDescription) {
            this.statusBox.textDescription.setOpacity(153);
        }

        cleverapps.tooltipManager.remove(this.icon);
        this.clickHandler.remove();
    },

    pressed: function () {
        this.slot.onClick();
    },

    highlightOn: function () {
        this.icon.highlight.setVisible(true);
        this.icon.runAction(
            cc.repeatForever(
                cc.sequence(
                    cc.scaleTo(0.4, 1.1).easing(cc.easeOut(1)),
                    cc.scaleTo(0.4, 1).easing(cc.easeIn(1))
                )
            )
        );
    },

    highlightOff: function () {
        this.icon.stopAllActions();
        this.icon.setScale(1);
        this.icon.highlight.setVisible(false);
    },

    wiggleOn: function () {
        this.scoreBg.runAction(
            cc.repeatForever(
                cc.sequence(
                    cc.rotateTo(0.2, -5).easing(cc.easeIn(1)),
                    cc.rotateTo(0.2, 5).easing(cc.easeOut(1))
                )
            )
        );
    },

    wiggleOff: function () {
        this.scoreBg.stopAllActions();
        this.scoreBg.setRotation(0);
    },

    animateBonusReceive: function (delay) {
        if (this.slot.used) {
            var scaleOrigin = this.resultText.getScale();
            this.resultText.runAction(
                new cc.Sequence(
                    new cc.DelayTime(delay || 0),
                    new cc.Spawn(
                        new cc.ScaleTo(0.2, scaleOrigin * 1.6).easing(cc.easeIn(1)),
                        new cc.TintTo(0.1, 255, 255, 255)
                    ),
                    new cc.CallFunc(function () {
                        this.resultText.setColor(cleverapps.styles.FONTS.SLOT_SCORE_PLAYED_TEXT.color);
                    }.bind(this)),
                    new cc.ScaleTo(0.2, scaleOrigin).easing(cc.easeOut(1))
                )
            );
        } else {
            this.scoreBg.runAction(
                cc.sequence(
                    new cc.DelayTime(delay || 0),
                    new cc.ScaleBy(0.2, 1.25).easing(cc.easeOut(3)),
                    new cc.ScaleBy(0.2, 0.8).easing(cc.easeIn(3))
                )
            );
        }
    },

    animateHighlight: function () {
        this.slotBg.setVisible(true);
        this.slotBg.setOpacity(0);
        this.slotBg.runAction(new cc.Sequence(
            new cc.FadeIn(0.5),
            new cc.FadeOut(0.5),
            new cc.CallFunc(function () {
                this.slotBg.setVisible(false);
                this.slotBg.setOpacity(255);
            }.bind(this))
        ));
    }
});

cleverapps.styles.Slot = {
    selected: {
        padding: {
            width: 18,
            height: 10
        },
        position: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 2 }
        }
    },
    margin: 10,
    resultBox: {
        text: {
            position: {
                x: { align: "center" },
                y: { align: "center" }
            }
        },
        completed_sign: {
            x: { align: "center", dx: 55 },
            y: { align: "center", dy: -40 }
        }
    },
    statusBox: {
        position: {
            x: { align: "center" },
            y: { align: "center" }
        },
        height: 125,
        width: 155
    },
    tooltip: {
        firstLine: {
            position: {
                x: { align: "center" },
                y: { align: "center", dy: 20 }
            }
        },
        secondLine: {
            position: {
                x: { align: "center" },
                y: { align: "center", dy: -20 }
            }
        }
    },

    highlight: {
        width: 146,
        height: 146,
        x: { align: "center" },
        y: { align: "center", dy: 2 }
    }
};

cleverapps.styles.COLORS.GREEN_SLOT = new cc.Color(0, 147, 4, 255);
cleverapps.styles.COLORS.VIOLET_TOOLTIP = new cc.Color(165, 86, 255, 255);

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    TOOLTIP_TEXT_VIOLET: {
        size: 30,
        color: cleverapps.styles.COLORS.VIOLET_TOOLTIP
    },

    SLOT_TITLE: {
        size: 25,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    SLOT_SCORE_TEXT: {
        size: 50,
        color: new cc.Color(143, 46, 22, 255)
    },

    SLOT_SCORE_TEXT_GRAY: {
        size: 50,
        color: cleverapps.styles.COLORS.COLOR_GRAY
    },

    SLOT_SCORE_PLAYED_TEXT: {
        size: 50,
        stroke: undefined,
        shadow: undefined,
        color: cleverapps.styles.COLORS.VIOLET_TOOLTIP
    }
});
