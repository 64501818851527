/**
 * Created by mac on 3/3/24
 */

AdsLimits.TYPES.MEDAL_COLLECTION = "medalcollection";

DataLoader.TYPES.MEDAL_COLLECTION = "_medal_collection";

RewardTypes.medals = {
    getIcon: function () {
        return bundles.reward_icons.frames.medal_png;
    },

    handler: function (value) {
        aisensia.medalCollection.addMore(value);
    }
};
