/**
 * Created by Mikhail Menshenin on 2/29/24
 */

var MedalCollection = function () {
    cleverapps.EventEmitter.call(this);

    this.counter = new Counter();

    this.load();

    cleverapps.adsLimits.on("update", this.updateAds.bind(this));
};

MedalCollection.MAX_LEVEL = 20;
MedalCollection.MAX_LEVEL_AUTODETECT = true;
MedalCollection.FIELD_ROW_COUNT = 4;
MedalCollection.FIELD_COLUMN_COUNT = 4;
MedalCollection.AD_REWARD = 4;

MedalCollection.prototype = Object.create(cleverapps.EventEmitter.prototype);
MedalCollection.prototype.constructor = MedalCollection;

MedalCollection.prototype.getInfo = function () {
    return {
        medals: this.medals.slice(),
        spawns: this.spawns
    };
};

MedalCollection.prototype.updateInfo = function (data) {
    this.medals = data && data.medals || [];
    this.spawns = data && data.spawns || 0;
};

MedalCollection.prototype.getMedal = function (x, y) {
    return this.medals.find(function (medal) {
        return medal.x === x && medal.y === y;
    });
};

MedalCollection.prototype.createMedal = function (x, y, level) {
    if (this.getMedal(x, y)) {
        var message = "MEDAL COLLECTION: add medal at place with medal: " + JSON.stringify();
        if (cleverapps.config.debugMode) {
            throw message;
        }
        cleverapps.throwAsync(message);
        return;
    }

    var medal = { x: x, y: y, level: level };
    this.medals.push(medal);
};

MedalCollection.prototype.removeMedal = function (removeMedal) {
    this.medals = this.medals.filter(function (medal) {
        return medal.x !== removeMedal.x || medal.y !== removeMedal.y;
    });
};

MedalCollection.prototype.load = function () {
    var data = cleverapps.dataLoader.load(DataLoader.TYPES.MEDAL_COLLECTION) || {};
    this.updateInfo(data);
};

MedalCollection.prototype.save = function (amount) {
    this.spawns += amount || 0;
    cleverapps.dataLoader.save(DataLoader.TYPES.MEDAL_COLLECTION, this.getInfo());

    if (amount) {
        this.trigger("updateCurrency");
    }
};

MedalCollection.prototype.getCurrentLevel = function () {
    var maxLevel = 0;

    for (var i = 0; i < this.medals.length; i++) {
        var medal = this.medals[i];

        if (maxLevel < medal.level) {
            maxLevel = medal.level;
        }
    }

    return maxLevel;
};

MedalCollection.prototype.getFreeCells = function () {
    return this.createCells().filter(function (cell) {
        return !this.getMedal(cell.x, cell.y);
    }.bind(this));
};

MedalCollection.prototype.createCells = function () {
    var cells = [];

    for (var y = 0; y < MedalCollection.FIELD_ROW_COUNT; y++) {
        for (var x = 0; x < MedalCollection.FIELD_COLUMN_COUNT; x++) {
            cells.push({ x: x, y: y });
        }
    }

    return cells;
};

MedalCollection.prototype.spawn = function (level) {
    level = level || 1;

    if (this.counter.isActive()) {
        return;
    }

    if (!this.spawns) {
        this.watchAd();
        return;
    }

    var cell = cleverapps.Random.choose(this.getFreeCells());

    if (!cell) {
        return;
    }

    var currentLevel = this.getCurrentLevel();

    this.createMedal(cell.x, cell.y, level);
    this.save(-1);

    if (level > currentLevel) {
        this.counter.inc();
    }

    this.trigger("spawn", cell, function () {
        if (level > currentLevel) {
            this.trigger("unlocked", level);
            this.counter.dec();
        }
    }.bind(this));

    cleverapps.audio.playSound(bundles.main.urls.collections_open);
};

MedalCollection.prototype.hover = function (cellFrom, cellTo) {
    var medalFrom = cellFrom && this.getMedal(cellFrom.x, cellFrom.y);
    var medalTo = cellTo && this.getMedal(cellTo.x, cellTo.y);

    if (medalFrom && medalTo && medalFrom !== medalTo && medalFrom.level === medalTo.level && medalTo.level < MedalCollection.MAX_LEVEL) {
        this.trigger("select", cellTo);
    } else {
        this.trigger("unselect");
    }
};

MedalCollection.prototype.drag = function (cellFrom) {
    var medalFrom = cellFrom && this.getMedal(cellFrom.x, cellFrom.y);

    if (medalFrom) {
        this.medals.forEach(function (medal) {
            if (medal.level >= MedalCollection.MAX_LEVEL) {
                return;
            }

            if (medal === medalFrom) {
                return;
            }

            if (medal.level === medalFrom.level) {
                this.trigger("select", medal);
            }
        }.bind(this));
    }
};

MedalCollection.prototype.drop = function (cellFrom, cellTo) {
    this.trigger("unselect");

    if (!cellTo) {
        this.trigger("drop", cellFrom);

        cleverapps.audio.playSound(bundles.main.urls.collections_put);
        return;
    }

    var medalFrom = this.getMedal(cellFrom.x, cellFrom.y);
    var medalTo = this.getMedal(cellTo.x, cellTo.y);
    if (!medalTo) {
        medalFrom.x = cellTo.x;
        medalFrom.y = cellTo.y;
        this.save();

        this.trigger("drop", cellFrom, cellTo);

        cleverapps.audio.playSound(bundles.main.urls.collections_put);
        return;
    }

    if (medalTo.level !== medalFrom.level || medalTo === medalFrom) {
        this.trigger("drop", cellFrom);

        cleverapps.audio.playSound(bundles.main.urls.collections_put);
        return;
    }

    if (medalTo.level < MedalCollection.MAX_LEVEL) {
        var currentLevel = this.getCurrentLevel();
        var badgeLevel = medalTo.level += 1;
        this.removeMedal(medalFrom);
        this.save();

        if (badgeLevel > currentLevel) {
            this.counter.inc();
        }

        this.trigger("merge", cellFrom, cellTo, medalTo.level, function () {
            if (badgeLevel > currentLevel) {
                this.trigger("unlocked", badgeLevel);
                this.counter.dec();
            }
            cleverapps.eventBus.trigger("taskEvent", DailyTasks.MERGE_MEDALS);
        }.bind(this));

        cleverapps.audio.playSound(bundles.main.urls.collections_merge);
        return;
    }

    cleverapps.audio.playSound(bundles.main.urls.collections_put);
    this.trigger("drop", cellFrom);
};

MedalCollection.prototype.addMore = function (inc) {
    this.save(inc);
};

MedalCollection.prototype.spawnNext = function () {
    if (cleverapps.config.debugMode) {
        var cell = cleverapps.Random.choose(this.getFreeCells());

        if (!cell) {
            return;
        }

        var currentLevel = this.getCurrentLevel();

        if (currentLevel >= MedalCollection.MAX_LEVEL) {
            return;
        }

        this.createMedal(cell.x, cell.y, currentLevel + 1);
        this.save(-1);

        this.trigger("spawn", cell);
    }
};

MedalCollection.prototype.isAdsReady = function () {
    return cleverapps.adsLimits.state(AdsLimits.TYPES.MEDAL_COLLECTION) === AdsLimits.STATE_READY;
};

MedalCollection.prototype.watchAd = function () {
    if (this.isAdsReady()) {
        cleverapps.rewardedAdsManager.playRewarded(AdsLimits.TYPES.MEDAL_COLLECTION, function () {
            cleverapps.adsLimits.watch(AdsLimits.TYPES.MEDAL_COLLECTION);

            this.save(MedalCollection.AD_REWARD);
        }.bind(this));
    } else {
        this.trigger("updateAds");
    }
};

MedalCollection.prototype.updateAds = function () {
    this.trigger("updateAds");
};

MedalCollection.prototype.isAvailable = function () {
    return true;
};

MedalCollection.prototype.canShow = function () {
    return this.spawns || this.medals.length;
};

MedalCollection.prototype.gamePlayed = function (outcome, game) {
    if (game.outcome !== GameBase.OUTCOME_VICTORY) {
        return;
    }
    var medals = 1;
    var score = game.score.points;
    if (score > 250) {
        medals = 4;
    } else if (score > 200) {
        medals = 3;
    } else if (score > 150) {
        medals = 2;
    }

    game.rewards.medals = medals;
};

MedalCollection.prototype.isAttentionRequired = function () {
    return Boolean(this.spawns);
};

MedalCollection.prototype.reset = function () {
    this.medals = [];
    this.spawns = 0;

    this.save();
};

cleverapps.whenAllInitialized(function () {
    if (MedalCollection.MAX_LEVEL_AUTODETECT) {
        for (var maxLevel = 1; ; maxLevel++) {
            if (!bundles.medalcollection.frames["medal_" + maxLevel]) {
                maxLevel--;
                break;
            }
        }

        MedalCollection.MAX_LEVEL = maxLevel;
    }

    aisensia.medalCollection = new MedalCollection();
    cleverapps.sideBar.addTemporaryIcon(new MedalSidebarIcon());
});
