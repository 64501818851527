/**
 * Created by mac on 1/20/21
 */

cleverapps.styles.COLORS = cleverapps.overrideColors(cleverapps.styles.COLORS, {
    COLOR_BONUS: new cc.Color(215, 169, 255, 255),
    SHOP_STROKE_COLOR: new cc.Color(0, 0, 0, 100),
    SHOP_SHADOW_COLOR: new cc.Color(0, 0, 0, 80),
    TILE_REWARDS_COLOR: new cc.Color(9, 89, 151, 255),
    LIGHT_TEXT_COLOR: new cc.Color(255, 255, 255, 255),
    YELLOW_TEXT_COLOR: new cc.Color(247, 230, 43, 255),
    LIGHT_TEXT_DISABLED_COLOR: new cc.Color(200, 200, 200, 255),

    GREEN: new cc.Color(123, 194, 63, 255),
    COLOR_RED: new cc.Color(255, 74, 74, 255),

    DARK_TEXT_COLOR: new cc.Color(143, 46, 22, 255),
    DARK_BLUE_TEXT_COLOR: new cc.Color(50, 25, 119, 255),

    DARK_BROWN_COLOR: new cc.Color(175, 74, 25),

    RARITY_COLOR_0: new cc.Color(255, 255, 255, 255),
    RARITY_COLOR_1: new cc.Color(23, 184, 243, 255),
    RARITY_COLOR_2: new cc.Color(164, 17, 218, 255),
    RARITY_COLOR_3: new cc.Color(247, 231, 80, 255),

    LIGHT_BROWN: new cc.Color(177, 82, 28, 255),

    DARK_GRAY: new cc.Color(128, 128, 128, 255),

    LIGHT_VIOLET: new cc.Color(147, 71, 232, 255),

    DARK_BLUE: new cc.Color(28, 101, 189, 255),

    ORANGE_TEXT_COLOR: new cc.Color(248, 203, 40, 255),
    DARK_BROWN_STROKE: new cc.Color(148, 91, 56)
});

cleverapps.overrideStyles(cleverapps.styles.UI.Button, {
    keepRadius: true
});

cleverapps.overrideStyles(cleverapps.styles.VictoryWindow, {
    rewards: {
        shine: true
    }
});

cleverapps.styles.ControlButton = cleverapps.overrideStyles(cleverapps.styles.ControlButton, {
    default: {
        SKIP_RESOLUTION: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0.1
            }
        }
    },
    alternate: {
        SKIP_RESOLUTION: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0.1
            }
        }
    }
});

(function () {
    [
        cleverapps.styles.UI.Button.Images.button_green,
        cleverapps.styles.UI.Button.Images.button_blue,
        cleverapps.styles.UI.Button.Images.button_red,
        cleverapps.styles.UI.Button.Images.button_purple,
        cleverapps.styles.UI.Button.Images.small_button_blue,
        cleverapps.styles.UI.Button.Images.small_button_green,
        cleverapps.styles.UI.Button.Images.field_button_green,
        cleverapps.styles.UI.Button.Images.field_button_blue
    ].forEach(function (obj) {
        cleverapps.overrideStyles(obj, {
            SKIP_RESOLUTION: {
                padding: {
                    left: 0.3,
                    right: 0.3,
                    top: 0.05,
                    bottom: 0.12
                }
            }
        });
    });
}());

cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    TITLE_TEXT_STROKE: {
        color: new cc.Color(0, 0, 0, 77),
        size: 1
    },

    TITLE_TEXT_SHADOW: {
        color: new cc.Color(191, 66, 27, 127),
        direction: cc.size(0, -3),
        blur: 3
    },

    LIGHT_TEXT_STROKE: {
        color: cleverapps.styles.COLORS.LIGHT_TEXT_STROKE_COLOR,
        size: 1
    },

    LIGHT_TEXT_SHADOW: {
        color: cleverapps.styles.COLORS.LIGHT_TEXT_SHADOW_COLOR,
        direction: cc.size(0, 1),
        blur: 0
    },

    LIGHT_TEXT_INVERSED_SHADOW: {
        color: cleverapps.styles.COLORS.LIGHT_TEXT_SHADOW_COLOR,
        direction: cc.size(0, -3),
        blur: 0
    },

    SHOP_STROKE: {
        color: cleverapps.styles.COLORS.SHOP_STROKE_COLOR,
        size: 1
    },

    SHOP_SHADOW: {
        color: cleverapps.styles.COLORS.SHOP_SHADOW_COLOR,
        direction: cc.size(0, -2),
        blur: 3
    },

    MESSAGE_TEXT_STROKE: {
        color: cleverapps.styles.COLORS.LIGHT_VIOLET,
        size: 3
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    TIMER_TEXT: {
        name: "default",
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    BOOSTER_BEFORE_AMOUNT_TEXT: {
        name: "default",
        size: 35
    },

    ROW_PLAYER_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    ROW_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    ROW_DATA_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    DAILY_TASK_REWARD_FONT: {
        name: "nostroke",
        size: 32,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    DAILY_TASK_FONT: {
        size: 37,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    DAILY_TASK_PROGRESS_FONT: {
        size: 26,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    DAILY_TASK_PROGRESS_FONT_LARGE: {
        size: 31,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    DAILY_TASK_CTA: {
        size: 30,
        color: cleverapps.styles.COLORS.DARK_BLUE
    },

    MENUBAR_TEXT: {
        name: "nostroke",
        size: 42,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    MENUBAR_TIMER_TEXT: {
        name: "nostroke",
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    TILE_SHOP_OFFER_TEXT: {
        name: "default",
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.SHOP_STROKE,
        size: 45
    },

    TILE_SHOP_COINS_OFFER: {
        name: "default",
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.SHOP_STROKE,
        size: 60
    },

    SHOP_WINDOW_SMALL_TILE_TITLE: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.SHOP_STROKE,
        shadow: cleverapps.styles.DECORATORS.SHOP_SHADOW
    },

    SHOP_LIVES_PRODUCT_TILE_DESCRIPTION_TEXT: {
        name: "nostroke",
        size: 26,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    TILE_REWARDS: {
        size: 40,
        name: "nostroke",
        color: cleverapps.styles.COLORS.TILE_REWARDS_COLOR
    },

    TILE_REWARDS_HEADER: {
        size: 30,
        name: "nostroke",
        color: cleverapps.styles.COLORS.TILE_REWARDS_COLOR
    },

    SHOP_WINDOW_TILE_TITLE: {
        name: "default",
        size: 55,
        color: cleverapps.styles.COLORS.WHITE
    },

    PRODUCT_TILE_LIMIT_TEXT: {
        name: "nostroke",
        size: 26,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    PACKWINDOW_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    SHOP_WINDOW_LIVES_TEXT: {
        name: "nostroke",
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR,
        size: 40
    },

    TILE_SHOP_BADGE_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.TILE_SHOP_BADGE_STROKE
    },

    PACK_TILE_REWARDS_TEXT: {
        name: "nostroke",
        size: 40,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    WINDOW_TITLE_TEXT: {
        size: 64,
        color: cleverapps.styles.COLORS.DARK_BROWN_COLOR,
        stroke: undefined,
        shadow: {
            color: new cc.Color(136, 56, 0, 255),
            direction: cc.size(0, 3),
            blur: 2
        }
    },
    NOBG_WINDOW_TITLE_TEXT: {
        size: 64,
        color: cleverapps.styles.COLORS.DARK_BROWN_COLOR,
        stroke: undefined,
        shadow: {
            color: new cc.Color(136, 56, 0, 255),
            direction: cc.size(0, 3),
            blur: 2
        }
    },
    WINDOW_SMALL_TEXT: {
        name: "nostroke",
        size: 30,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },
    TOURNAMENT_PLAYER_TEXT: {
        name: "nostroke",
        size: 30,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },
    WINDOW_TEXT: {
        name: "nostroke",
        size: 50,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },
    EXTRA_WINDOW_TEXT: {
        name: "nostroke",
        size: 40,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },
    LOADING_ANIMATION_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },
    SMALL_TEXT_ERROR: {
        size: 40,
        color: cleverapps.styles.COLORS.COLOR_RED
    },
    FBCONNECT_WINDOW_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },
    SETTINGS_WINDOW_ID_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },
    TERMS_WINDOW_TEXT: {
        size: 28,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    LIGHT_TITLE_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },
    LIGHT_TITLE_TEXT_DISABLED: {
        size: 50,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_DISABLED_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    UNIT_PROB: {
        size: 35,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    UNIT_AMOUNT: {
        size: 50,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    BUTTON_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    FORCE_MESSAGE_TEXT: {
        size: 39,
        color: cleverapps.styles.COLORS.LIGHT_BROWN
    },

    LIGHT_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    DARK_TEXT: {
        name: "nostroke",
        size: 45,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    LOADING_TEXT: {
        size: 45,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    REWARDS_LIST_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR
    },

    REWARD_WINDOW_REWARDS_TEXT: {
        name: "nostroke",
        size: 40,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    SIDEBAR_ICON_TEXT: {
        name: "nostroke",
        size: 38,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR,
        stroke: undefined,
        shadow: undefined
    },

    EXPEDITION_WINDOW_TEXT: {
        size: 32,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },
    CHAT_REWARD_COUNT_TEXT: {
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },
    RATE_WINDOW_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    GROWTH_FUND_LEVEL_TITLE: {
        size: 35,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    GROWTH_FUND_LEVEL_TITLE_REACHED: {
        size: 35,
        color: new cc.Color(145, 230, 54),
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    GROWTH_FUND_LEVEL_REWARD: {
        size: 35,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR,
        stroke: undefined,
        shadow: undefined
    },

    GROWTH_FUND_LEVEL_REWARD_REACHED: {
        size: 35,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR,
        stroke: undefined,
        shadow: undefined
    },

    GROWTH_FUND_LEVEL_VALUE: {
        size: 35,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    GROWTH_FUND_LEVEL_VALUE_REACHED: {
        size: 35,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    GROWTH_FUND_CTA2: {
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    GROWTH_FUND_CTA1: {
        color: cleverapps.styles.COLORS.YELLOW,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    GROWTH_FUND_RECEIVE_AMOUNT: {
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    FRIEND_REQUEST_TEXT: {
        name: "nostroke",
        size: 35,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    DIALOGUE_PERSON_TITLE_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    KNOCKOUT_WINDOW_COUNTDOWN_TEXT: {
        name: "nostroke",
        size: 70,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },

    COOLDOWNNODE_TIMER_TEXT: {
        name: "nostroke",
        size: 65,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    CLANS_ROW_LEADER_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    SUBSCRIPTION_BUTTON_TITLE: {
        size: 22,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_DISABLED_COLOR,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    SUBSCRIPTION_MAIN_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    TERMS_LINK_TEXT: {
        size: 26,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    TERMS_TEXT: {
        size: 22,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_DISABLED_COLOR,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    VIPREWARDEDWINDOW_DESCRIPTION_TEXT: {
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    VIPREWARDEDWINDOW_TIMER_TEXT: {
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    VIPREWARDEDWINDOW_NOTE_TEXT: {
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    LEVEL_TITLE_TEXT: {
        name: "nostroke",
        size: 65,
        color: new cc.Color(48, 83, 120, 255)
    },

    LEVEL_CURRENT_TITLE_TEXT: {
        size: 40
    },

    LEVEL_NOT_PASSED_TITLE_TEXT: {
        name: "nostroke",
        size: 40,
        color: new cc.Color(58, 58, 58, 255)
    },

    PROLONG_BUTTON_AMOUNT_TEXT: {
        size: 32,
        color: cleverapps.styles.COLORS.WHITE
    },

    SHOP_USER_GOLD_TEXT: {
        name: "nostroke",
        size: 50,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    BONUSSALE_PRODUCT_REWARD: {
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.SHOP_STROKE,
        size: 70
    },

    BONUSSALE_REWARDS: {
        size: 28,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE
    },

    BONUSSALE_PLUS: {
        size: 55,
        color: cleverapps.styles.COLORS.YELLOW,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    BONUSSALE_BADGE: {
        size: 30,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    BONUSBG_TITLE: {
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: {
            color: new cc.Color(36, 79, 251, 255),
            size: 3
        }
    },

    BONUSSALE_BONUS_REWARD: {
        size: 40
    },

    VICTORY_TEXT: {
        size: 55,
        color: cleverapps.styles.COLORS.DARK_BROWN_COLOR,
        shadow: {
            color: new cc.Color(136, 56, 0, 255),
            direction: cc.size(0, 3),
            blur: 2
        }
    },

    RIBBON_TEXT: {
        size: 32,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    BACKGROUND_NAME_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    GRAY_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.COLOR_GRAY
    },

    YATZY_BUTTON_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE
    },

    RESULTS_TEXT: {
        size: 40,
        color: new cc.Color(246, 217, 104, 255),
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE,
        shadow: cleverapps.styles.DECORATORS.IMAGE_FONT_SHADOW
    },

    RESULTS_DOUBLE_TEXT: {
        size: 28,
        color: new cc.Color(246, 217, 104, 255),
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE,
        shadow: cleverapps.styles.DECORATORS.IMAGE_FONT_SHADOW
    },

    SCORE_TEXT_SINGLE: {
        size: 60,
        color: cleverapps.styles.COLORS.WHITE
    },

    SCORE_TEXT_DOUBLE: {
        size: 50,
        color: cleverapps.styles.COLORS.WHITE
    },

    GOAL_TEXT: {
        size: 60,
        color: new cc.Color(246, 217, 104, 255),
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE,
        shadow: cleverapps.styles.DECORATORS.IMAGE_FONT_SHADOW
    },

    LOSE_WINDOW_TEXT: {
        size: 55,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE
    },

    MESSAGE_TEXT_NORMAL: {
        size: 130,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.MESSAGE_TEXT_STROKE
    },

    CLOVERS_TEXT: {
        size: 56
    },

    SCORE_TEXT_VICTORY_WINDOW: {
        size: 50,
        color: cleverapps.styles.COLORS.WHITE
    },

    SCORE_RESULT_VICTORY_WINDOW: {
        size: 70,
        color: cleverapps.styles.COLORS.WHITE
    },

    NOTIFICATION_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.DARK_BLUE
    },

    NOTIFICATION_PROGRESS_FONT: {
        size: 31,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    REWARD_TEXT: {
        size: 70,
        color: cleverapps.styles.COLORS.WHITE
    },

    MISSION_WINDOW_COUNTDOWN_TEXT: {
        size: 37,
        color: cleverapps.styles.COLORS.WHITE
    }
});

cleverapps.overrideStyles(cleverapps.styles.SideBarIconView, {
    lock: {
        x: { align: "right", dx: 4 },
        y: { align: "bottom", dy: 39 }
    },

    text: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -15 },
        width: 180,
        height: 74,
        offsetY: 2
    },

    mark: {
        x: { align: "right", dx: 7 },
        y: { align: "top", dy: -23 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.DailyTaskTimer, {
    offsetY: 3
});

cleverapps.overrideStyles(cleverapps.styles.Timer, {
    width: 270,
    height: 80,

    timer: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 0 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.DailyTasksWindow, {
    timer: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -60 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PackWindow, {
    animation: {
        default: {
            dx: 0,
            dy: 40
        },

        starterPack0: {
            idleAnimation: "0_idle",
            introAnimation: "0_start"
        },

        starterPack: {
            idleAnimation: "1_idle",
            introAnimation: "1_start"
        },

        starterPack2: {
            idleAnimation: "2_idle",
            introAnimation: "2_start"
        }
    },

    title: {
        margin: {
            y: -90
        }
    },

    rewards: {
        delay: 0.5,
        duration: 0.5,
        fitTo: {
            width: 150
        },

        positions: {
            default: {
                hard: {
                    x: 0,
                    y: -390
                },
                soft: {
                    x: 0,
                    y: -50
                }
            }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.DailyTasksTab, {
    margin: 15,

    scroll: {
        paddingX: 0,
        height: 680
    },

    padding: {
        x: 40,
        bottom: 40,
        top: 100
    },

    timer: {
        padding: { x: 0, y: 15 }
    },

    foreground: {
        width: 850,
        height: 700,
        wide: {
            width: 1178
        },
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: 15 }
    },

    tasks: {
        margin: 0,
        padding: {
            x: 0,
            top: -5
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.NotificationView, {
    minWidth: 480,
    padding: {
        x: 40,
        y: 20
    },

    image: {
        margin: 32
    },

    progress: {
        type: "notification"
    }
});

cleverapps.overrideStyles(cleverapps.styles.DailyTasksCompleteAllView, {
    margin: 60,

    padding: {
        left: 70,
        right: 80,
        top: 15,
        bottom: 15
    },

    prize: {
        y: { align: "bottom", dy: -19 }
    },

    bar: {
        height: 45,
        progressFrames: {
            0: bundles.progress_bar.frames.bar_red_dailytask,
            30: bundles.progress_bar.frames.bar_orange_dailytask,
            70: bundles.progress_bar.frames.bar_dailytask
        }
    },

    textLayout: {
        width: 320
    }
});

cleverapps.overrideStyles(cleverapps.styles.PrizeBarComponent, {
    prize: {
        width: 85,
        height: 85,

        x: { align: "right", dx: 50 },
        y: { align: "center", dy: 10 },

        maxScale: 1.4,
        flyY: 10,

        present: {
            dy: 8
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.MenuBar, {
    margin: 0,
    smallMargin: -18
});

cleverapps.overrideStyles(cleverapps.styles.MenuBarItem, {
    bg: {
        width: 290,
        height: 85,
        minWidth: 247,
        padding: {
            bottom: 3
        }
    },

    icon: {
        x: 26,
        y: 5
    },

    iconText: {
        position: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 10 }
        }
    },

    timer: {
        icon: undefined,
        position: {
            x: { align: "center", dx: 30 },
            y: { align: "bottom", dy: -36 }
        },

        text: {
            y: { align: "center", dy: -6 }
        }
    },

    plusButton: {
        offset: {
            x: -22,
            y: 10
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.SideBarSlot, {
    wideModePadding: [
        {
            top: 270,
            horizontal: 20
        },
        {
            top: 270,
            horizontal: 40
        },
        {
            top: 290,
            horizontal: 50
        }
    ],
    margin: 25
});

cleverapps.overrideStyles(cleverapps.styles.ToolbarView, {
    bg: {
        height: 110,
        padding: 20,
        offsetY: -10
    },
    items: {
        dy: 5
    },
    margin: 10
});

cleverapps.overrideStyles(cleverapps.styles.BandButton, {
    width: 82,
    height: 200,

    mobileSize: {
        width: 82,
        height: 150
    },

    position: {
        x: { align: "right", dx: -15 },
        y: { align: "top", dy: 40 }
    },

    mobilePosition: {
        x: { align: "right", dx: -40 },
        y: { align: "top", dy: 40 }
    },

    icon: {
        x: { align: "center" },
        y: { align: "bottom", dy: 35 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.TableView, {
    padding: 13,
    innerPadding: 10
});

cleverapps.overrideStyles(cleverapps.styles.Row, {
    height: 82,

    content: {
        y: { align: "center", dy: -2 }
    },

    data: {
        icon: {
            rotation: 0
        },
        text: {
            width: 70
        },
        bg: {
            height: 60
        }
    }
});

if (cleverapps.config.type === "merge") {
    cleverapps.overrideStyles(cleverapps.styles.Row, {
        width: 760
    });
}

cleverapps.overrideStyles(cleverapps.styles.FriendRequestView, {
    width: 580,
    height: 230,

    processButton: {
        height: 70,
        x: 50
    },

    Avatar: {
        Margin: {
            x: 50
        }
    },

    Request: {
        width: 300
    },

    close: {
        x: 28,
        y: 12
    }
});

cleverapps.overrideStyles(cleverapps.styles.FriendRequestsWindow, {
    y: 150
});

cleverapps.overrideStyles(cleverapps.styles.LevelStartWindow, {
    padding: {
        top: 0,
        bottom: 15
    }
});

if (cleverapps.styles.BoosterBeforeView) {
    cleverapps.overrideStyles(cleverapps.styles.BoosterBeforeView, {
        textAvailable: {
            y: { align: "bottom", dy: 9 },

            bg: {
                y: { align: "bottom", dy: -15 },
                height: 70
            }
        }
    });
}

if (cleverapps.styles.BoosterBeforeAmountView) {
    cleverapps.overrideStyles(cleverapps.styles.BoosterBeforeAmountView, {
        amount: {
            y: { align: "center", dy: 2 }
        }
    });
}

if (cleverapps.styles.BoostersBeforeComponent) {
    cleverapps.overrideStyles(cleverapps.styles.BoostersBeforeComponent, {
        contentMargin: 30
    });
}

cleverapps.overrideStyles(cleverapps.styles.SelectFriendWindow, {
    withFriends: {
        height: 730
    }
});

cleverapps.overrideStyles(cleverapps.styles.TacticalExtendWindow, {
    animation: {
        height: 400
    }
});

cleverapps.overrideStyles(cleverapps.styles.LoseWindow, {
    livesLeftMargin: 60,

    content: {
        margin: 60
    },

    button: {
        width: 270,
        height: 115,
        x: { align: "center" },
        y: { align: "bottom", dy: 120 }
    },

    title: {
        x: { align: "center" },
        y: { align: "top", dy: -70 }
    },

    noBackground: true
});

cleverapps.overrideStyles(cleverapps.styles.RestartWindow, {
    button: {
        width: 220,
        height: 90
    }
});

cleverapps.overrideStyles(cleverapps.styles.ReloadWindow, {
    button: {
        height: 90
    }
});

if (cleverapps.styles.HeroWindow) {
    cleverapps.overrideStyles(cleverapps.styles.HeroWindow, {
        padding: {
            bottom: 70
        },

        helpBlock: {
            text: {
                width: 400
            }
        }
    });
}

if (cleverapps.styles.ExtraWindow) {
    cleverapps.overrideStyles(cleverapps.styles.ExtraWindow, {
        margin: 40,
        window: {
            padding: {
                left: 30,
                top: 80,
                right: 30,
                bottom: 80
            },
            CloseButton: {
                x: { align: "right", dx: 30 },
                y: { align: "top", dy: 30 }
            }
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.DialogueView, {
    widthPadding: 50,
    offsetY: 10,

    persons: {
        aboveContent: false
    },
    positions: {
        bottom: {
            y: 30
        }
    },

    text: {
        arrow: {
            y: { align: "top", dy: 36 }
        },
        padding: {
            fromWallX: 50,
            x: 50,
            y: 50
        },
        verticalPadding: {
            fromWallX: 50,
            x: 50,
            y: 45
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.MinimalDialogue, {
    person: {
        margin: 30
    },
    text: {
        arrow: {
            left: {
                x: { align: "left", dx: -29 },
                y: { align: "center", dy: 0 }
            },
            right: {
                x: { align: "right", dx: 30 },
                y: { align: "center", dy: 0 }
            }
        },

        verticalPadding: {
            top: 20,
            bottom: 18,
            left: 25,
            right: 25
        }
    }
});

if (cleverapps.styles.LanternWindow) {
    cleverapps.overrideStyles(cleverapps.styles.LanternWindow, {
        flashingArrows: true,
        bonuses: {
            margin: 10,
            images: {
                moves: {
                    scale: 1.0
                }
            },
            y: { align: "bottom", dy: 30 }
        },
        animation: {
            y: 80,
            scale: 0.8
        },
        text: {
            width: 668
        }
    });
}

if (cleverapps.styles.ProlongationOfferView) {
    cleverapps.overrideStyles(cleverapps.styles.ProlongationOfferView, {
        label: {
            amount: {
                x: { align: "center", dx: -3 },
                y: { align: "center", dy: -15 }
            }
        },

        bonusLabel: {
            x: { align: "center", dx: 145 },
            y: { align: "center", dy: 73 }
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.LeadersWindow, {
    arrows: {
        dec: {
            x: { align: "left", dx: -145 }
        },

        inc: {
            x: { align: "right", dx: 145 }
        }
    }
});

if (cleverapps.styles.BackgroundsWindow) {
    cleverapps.overrideStyles(cleverapps.styles.BackgroundsWindow, {
        padding: {
            right: -58,
            left: -60
        },
        present: {
            button: {
                x: { align: "center", dx: 10 }

            }
        },

        tape: {
            x: { align: "right", dx: 31 },
            withName: {
                x: { align: "right", dx: 31 }
            },
            text: {
                y: 65
            }
        },

        item: {
            bg: {
                padding: {
                    x: 10,
                    y: 12
                }
            }
        },

        lockWithName: {
            x: { align: "center" },
            y: { align: "center", dy: 0 }
        },

        name: {
            x: { align: "center" },
            y: { align: "top", dy: -30 }
        }
    });
}

if (cleverapps.styles.OrdersWindow) {
    cleverapps.overrideStyles(cleverapps.styles.OrdersWindow, {
        Background: {
            image: bundles.orderswindow.frames.ow_window_background
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.DailyTaskView, {
    padding: {
        left: 15,
        right: 30,
        y: 10
    },

    margin: 15,

    reward: {
        textMargin: 5
    },

    icon: {
        bg: {
            wide: {
                width: 135
            },

            width: 135,
            height: 135
        }
    },

    titleAndRewards: {
        width: 300
    },

    progressBar: {
        width: 210,
        text: {
            dy: 2
        },
        progressFrames: {
            0: bundles.progress_bar.frames.bar_red_dailytask,
            30: bundles.progress_bar.frames.bar_orange_dailytask,
            70: bundles.progress_bar.frames.bar_dailytask
        }
    },

    button: {
        width: 210
    }
});

cleverapps.overrideStyles(cleverapps.styles.BaseProductTile, {
    width: 390,
    height: 690,
    badge: {
        x: { align: "right", dx: 39 },
        y: { align: "top", dy: 39 }
    },

    limit: {
        y: -30
    },

    title: {
        height: 110,
        width: 400,
        x: 0,
        y: -10,
        text: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        },
        bg: {
            x: { align: "left", dx: -16 },
            y: { align: "center", dy: 0 }
        }
    },

    description: {
        width: 480,
        height: 138,
        bg: {
            width: 480,
            height: 138,
            y: { align: "center", dy: -5 },
            x: { align: "center", dx: 7 }
        }
    },

    background: {
        adjustBackgroundCapInsets: true,

        darkBg: {
            padding: {
                width: 80,
                height: 250
            },
            x: { align: "center" },
            y: { align: "center", dy: 90 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.TileBadge, {
    x: { align: "center", dx: -3 },
    y: { align: "center", dy: 3 }
});

cleverapps.overrideStyles(cleverapps.styles.TileButton, {
    width: 300,
    height: 110
});

cleverapps.overrideStyles(cleverapps.styles.TileIcon, {
    height: undefined,
    wrap: {
        dx: 15,
        dy: 20
    },

    subsMonth: {
        wrap: { dy: -15 }
    },

    growthFund: {
        wrap: { dy: -40 }
    }
});

if (cleverapps.styles.StarsProgressBar) {
    cleverapps.overrideStyles(cleverapps.styles.StarsProgressBar, {
        bar: {
            offsetX: 6,
            height: 82,
            dy: 5
        },

        barAnimation: {
            offsetX: -15
        }
    });
}

if (cleverapps.styles.LivesProductTile) {
    cleverapps.overrideStyles(cleverapps.styles.LivesProductTile, {
        width: 400,
        height: 690,

        content: {
            y: { align: "bottom", dy: 45 },
            margin: 10
        },

        limit: {
            y: -10
        },

        button: {
            width: 235,
            height: 75
        },

        description: {
            y: 140
        },

        badge: {
            x: { align: "right", dx: 40 },
            y: { align: "top", dy: 40 },
            scale: 1
        },

        title: {
            height: 110,
            width: 400,
            y: -65,
            text: {
                x: { align: "center", dx: 0 },
                y: { align: "center", dy: 0 }
            }
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.SubscriptionButtons, {
    buttonWidth: 410,
    buttonHeight: 120
});

cleverapps.overrideStyles(cleverapps.styles.SubscriptionTile, {
    width: 460,
    height: 747,

    content: {
        margin: 0,
        y: { align: "bottom", dy: 75 }
    },

    description: {
        height: 154,
        width: 380,

        y: 8,

        rewardsMargin: {
            y: -2
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.GrowthFundTile, {
    description: {
        height: 154,
        y: 8
    },
    content: {
        margin: 0,
        y: { align: "bottom", dy: 75 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PackProductTile, {
    width: 460,
    height: 747,

    limit: {
        y: -20
    },

    content: {
        y: { align: "bottom", dy: 75 },
        margin: 0
    },

    description: {
        y: 20,
        x: -7,
        paddingY: -30,
        bg: {
            width: 380,
            height: 170
        }
    },

    title: {
        y: 20,

        text: {
            y: { align: "center", dy: 6 }
        },

        size: {
            width: 480
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.CoinsProductTile, {
    description: undefined,

    content: {
        y: { align: "bottom", dy: 75 },
        margin: 18
    },
    icon: {
        y: -50
    }
});

cleverapps.overrideStyles(cleverapps.styles.TileRewardsBlock, {
    rowMargin: 10,
    colMargin: 10
});

cleverapps.overrideStyles(cleverapps.styles.ShopWindow, {
    padding: { x: 30, y: 30 }
});

cleverapps.overrideStyles(cleverapps.styles.CloseButton, {
    x: { align: "right", dx: -40 },
    y: { align: "top", dy: -40 }
});

cleverapps.overrideStyles(cleverapps.styles.Window, {
    padding: {
        top: 100,
        bottom: 70
    },
    Background: {
        padding: {
            x: 10,
            y: 20
        }
    },
    titleMargin: 110,
    HelpButton: {
        lovesTitle: true
    },
    Footer: {
        x: { align: "center" },
        y: { align: "bottom", dy: 40 },

        margin: 20
    }
});

cleverapps.overrideStyles(cleverapps.styles.Tabs, {
    buttons: {
        width: 135,
        height: 240
    },

    attention: {
        x: { align: "left", dx: -20 },
        y: { align: "top", dy: -20 }
    },

    margin: 6
});

cleverapps.overrideStyles(cleverapps.styles.RateWindow, {
    width: 800,
    height: 400,

    text: {
        height: 400
    }
});

cleverapps.overrideStyles(cleverapps.styles.WindowTitle.Types, {
    bg_window: {
        background: {
            minWidth: 459,
            height: 127
        },
        titleSideOffset: {
            x: 160
        },
        textPosition: {
            y: { align: "center", dy: 4 }
        },
        position: {
            y: { align: "top", anchor: false, dy: -20 }
        },
        padding: {
            x: 40
        },
        helpButton: {
            x: { align: "left", dx: 20 },
            y: { align: "center", dy: -2 },
            padding: {
                x: 30
            }
        }
    },
    nobg_window: {
        background: {
            height: 176,
            minWidth: 640
        },
        position: {
            x: { align: "center" },
            y: { align: "top", dy: -30 }
        },
        mobilePosition: {
            x: { align: "center" },
            y: { align: "center", dy: 460 }
        },
        padding: {
            x: 185
        },
        textPosition: {
            x: { align: "center" },
            y: { align: "center", dy: 20 }
        },
        helpButton: {
            x: { align: "left", dx: 90 },
            y: { align: "center", dy: 10 },
            padding: {
                x: 14
            }
        }
    }
});

if (cleverapps.styles.ExtendUnlimitedLivesWindow) {
    cleverapps.overrideStyles(cleverapps.styles.ExtendUnlimitedLivesWindow, {
        animation: {
            height: 200
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.SoftCurrencyTile, {
    content: {
        y: { align: "bottom", dy: 70 },
        margin: 25
    },
    icon: {
        y: -50
    }
});

cleverapps.overrideStyles(cleverapps.styles.DailyCupView, {
    title: {
        text: {
            width: 430,

            y: { align: "center", dy: -18 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.FingerView, {
    anchorX: 0.1,
    anchorY: 0.9
});

if (cleverapps.styles.EpisodeView) {
    cleverapps.overrideStyles(cleverapps.styles.EpisodeView, {
        coming_soon: {
            activities: {
                button: {
                    height: 80
                }
            }
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.PassLastChanceWindow, {
    rewards: {
        text: {
            x: { align: "center", dx: -37 },
            y: { align: "center", dy: 36 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.MissionWindow, {
    window: {
        margin: 46,
        padding: {
            bottom: 50,
            top: 125
        }
    },

    button: {
        type: cleverapps.styles.UI.Button.Images.button_green
    },

    padding: {
        x: -30,
        y: -15
    },

    table: {
        height: 520
    },

    timerBackground: bundles.windows.frames.window_bottom_panel_png
});

cleverapps.overrideStyles(cleverapps.styles.ToolbarItemView, {
    mark: {
        x: { align: "center", dx: -32 },
        y: { align: "center", dy: 35 },
        baseScale: 0.72
    }
});

cleverapps.overrideStyles(cleverapps.styles.SettingsWindow, {
    window: {
        padding: {
            left: 70,
            right: 70,
            top: 120,
            bottom: 60
        }
    },

    button: {
        height: 110
    },

    loginButton: {
        width: 563
    }
});

cleverapps.overrideStyles(cleverapps.styles.DeleteAccountWindow, {
    window: {
        padding: {
            left: 50,
            right: 50,
            top: 60,
            bottom: 70
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.LevelView, {
    clickSize: {
        width: 120,
        height: 120
    },

    LevelId: {
        x: { dx: 0 },
        y: { dy: -10 },

        showTypes: [Level.TYPE_CURRENT, Level.TYPE_NOTPASSED]
    }
});

cleverapps.overrideStyles(cleverapps.styles.DialoguePersonTitleView, {
    mobileY: { align: "top", anchor: "center", dy: -10 },
    text: {
        padding: {
            x: 38,
            y: -20
        },
        offset: { y: 3 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.SettingsWindow, {
    button: {
        height: 120
    },
    loginButton: {
        height: 120
    }
});

cleverapps.overrideStyles(cleverapps.styles.RewardsListComponent, {
    margin: {
        x: 120,
        y: 30
    }
});

cleverapps.overrideStyles(cleverapps.styles.CloversView, {
    width: 275,
    height: 165,

    position: [
        {
            x: { align: "left", dx: 465 },
            y: { align: "center" }
        },
        {
            x: { align: "left", dx: 465 },
            y: { align: "center" }
        },
        {
            x: { align: "left", dx: 465 },
            y: { align: "center" }
        }
    ],

    icon: {
        x: { align: "left", dx: 40 },
        y: { align: "center", dy: 3 }
    },

    text: {
        x: { align: "center", dx: 50 },
        y: { align: "center" }
    }
});

cleverapps.overrideStyles(cleverapps.styles.Exclamations, {
    rotation: -8
});

cleverapps.overrideStyles(cleverapps.styles.UI.ImageFont, {
    offset: 1,
    fixedNumberWidth: {
        stretch: 0.8
    }
});

cleverapps.overrideStyles(cleverapps.styles.UpMenuContainer, {
    margin: 100,
    padding: {
        x: 20,
        y: 10
    }
});

cleverapps.overrideStyles(cleverapps.styles.VictoryWindow, {
    margin: 125,
    padding: {
        top: 0,
        bottom: -125
    },

    Background: {
        padding: {
            left: 40,
            right: 40
        }
    },

    wrapper: {
        position: {
            x: { align: "center" },
            y: { align: "center", dy: 160 }
        },
        shine: {
            x: { align: "center" },
            y: { align: "center", dy: 40 }
        }
    },

    customPrizes: {
        delay: 0
    },

    resultScore: {
        width: 350,
        height: 60,
        startOffsetY: 25,
        text: {
            x: { align: "center", dx: -50 },
            y: { align: "center" }
        },
        score: {
            x: { align: "center", dx: 200 },
            y: { align: "center" }
        }
    },

    rewards: {
        textMargin: 40,
        item_dy: 35
    }
});

cleverapps.overrideStyles(cleverapps.styles.PlayButtonView || {}, {
    position: [
        { x: { align: "center" }, y: { align: "bottom", dy: 135 } },
        { x: { align: "center" }, y: { align: "bottom", dy: 175 } },
        { x: { align: "center" }, y: { align: "bottom", dy: 175 } }
    ]
});

ScaledProgressBar.Types = cleverapps.overrideTemplates(ScaledProgressBar.Types, {
    notification: {
        progress: bundles.progress_bar.frames.bar_dailytask,
        background: bundles.progress_bar.frames.bg_dailytask_blue
    }
});