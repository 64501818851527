/**
 * Created by Slava on 18.06.2024
 */

LoseWindow.prototype.setupChildren = cleverapps.extendFunc(LoseWindow.prototype.setupChildren, function () {
    this._super();

    this.buttons.setPositionRound(cleverapps.styles.LoseWindow.button);
    this.windowTitle.setPositionRound(cleverapps.styles.LoseWindow.title);
});
