/**
 * Created by Mikhail Menshenin on 05.04.2024
 */

var MedalMergeTabView = cleverapps.Layout.extend({
    ctor: function (game) {
        var styles = cleverapps.styles.MedalMergeTabView;
        var currentLevel = game.getCurrentLevel();

        this.game = game;

        var header = new cc.Sprite(bundles.medalcollection.frames.medal_header_png);

        var headerIcon = this.headerIcon = new MedalShineView(currentLevel, { withShadow: true });
        headerIcon.setPositionRound(styles.header.icon);
        header.addChild(headerIcon);

        var gameField = new MedalFieldView(this.game);

        var spawnButton = this.spawnButton = this.createSpawnButton(styles.spawn);

        this.updateSpawnCount();

        this._super([header, gameField, spawnButton], {
            direction: cleverapps.UI.VERTICAL,
            padding: styles.padding
        });

        this.setPositionRound(styles);

        this.game.on("updateCurrency", this.updateSpawnCount.bind(this), this);
        this.game.on("updateAds", this.updateSpawnCount.bind(this), this);
        this.game.on("unlocked", this.updateHeaderIcon.bind(this), this);

        if (cleverapps.config.debugMode) {
            cleverapps.UI.onClick(this.circle, function () {
                this.game.addMore(1);
            }.bind(this));
        }
    },

    createSpawnButton: function (styles) {
        var spawnButton = new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.button_blue,
            width: styles.width,
            height: styles.height,
            text: "",
            onClicked: this.game.spawn.bind(this.game)
        });

        var spawnButtonIcon = this.spawnButtonIcon = new cc.Sprite(bundles.medalcollection.frames.spawn_icon_png);
        spawnButtonIcon.setPositionRound(styles.icon);
        spawnButton.addChild(spawnButtonIcon);

        var adsButtonIcon = this.adsButtonIcon = new cc.Sprite(bundles.medalcollection.frames.ads_icon_png);
        adsButtonIcon.setPositionRound(styles.icon);
        spawnButton.addChild(adsButtonIcon);

        var circle = this.circle = new cc.Sprite(bundles.medalcollection.frames.roll_ball_full_png);
        circle.setPositionRound(styles.circle);

        var countText = this.spawnCountText = new cleverapps.UI.ImageFont(this.game.spawns, cleverapps.styles.FONTS.MEDAL_SPAWN_COUNT_TEXT);
        countText.setPositionRound(styles.circle.number);
        circle.addChild(countText);

        spawnButton.addChild(circle);

        return spawnButton;
    },

    createAdsIcon: function () {
        var spawnButtonIcon = new cc.Sprite(bundles.medalcollection.frames.spawn_icon_png);
        var adsButtonIcon = new cc.Sprite(bundles.medalcollection.frames.ads_icon_png);

        return new cleverapps.Layout([spawnButtonIcon, adsButtonIcon], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: 10
        });
    },

    updateSpawnCount: function () {
        var spawns = this.game.spawns;
        var hasSpawns = spawns > 0;
        var hasAds = this.game.isAdsReady();

        this.spawnButtonIcon.setVisible(hasSpawns || !hasAds);
        this.adsButtonIcon.setVisible(!hasSpawns && hasAds);

        this.circle.setVisible(hasSpawns || !hasAds);
        this.spawnCountText.setString(spawns);

        if (!hasSpawns && !hasAds) {
            this.spawnButton.disable();
            this.spawnCountText.setColor(cleverapps.styles.COLORS.COLOR_GRAY);
            this.circle.setSpriteFrame(bundles.medalcollection.frames.roll_ball_full_off_png);
            this.spawnButtonIcon.setSpriteFrame(bundles.medalcollection.frames.spawn_off_icon_png);
        } else {
            this.spawnButton.enable();
            this.spawnCountText.setColor(cleverapps.styles.COLORS.WHITE);
            this.circle.setSpriteFrame(bundles.medalcollection.frames.roll_ball_full_png);
            this.spawnButtonIcon.setSpriteFrame(bundles.medalcollection.frames.spawn_icon_png);
        }
    },

    updateHeaderIcon: function (level) {
        this.headerIcon.setLevel(level);
    }
});

cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    MEDAL_SPAWN_COUNT_TEXT_SHADOW: {
        color: cleverapps.styles.COLORS.LIGHT_TEXT_SHADOW_COLOR,
        direction: cc.size(0, 4),
        blur: 0
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    MEDAL_SPAWN_COUNT_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        shadow: cleverapps.styles.DECORATORS.MEDAL_SPAWN_COUNT_TEXT_SHADOW
    }
});

cleverapps.styles.MedalMergeTabView = {
    x: { align: "center" },
    y: { align: "center" },

    padding: { top: 6 },

    header: {
        icon: {
            x: { align: "center", dx: 10 },
            y: { align: "center", dy: -36 }
        }
    },

    spawn: {
        width: 316,
        height: 120,

        icon: {
            x: { align: "center" },
            y: { align: "center", dy: 2 }
        },

        circle: {
            x: { align: "right", dx: 9 },
            y: { align: "top", dy: 9 },
            number: {
                x: { align: "center", dx: 2 },
                y: { align: "center", dy: 4 }
            }
        }
    }
};