/**
 * Created by olga on 02.08.2024
 */

Simple.prototype.amountLevelStars = function (game) {
    var score = game.score.points;
    if (score > 200) {
        return 3;
    }
    if (score > 150) {
        return 2;
    }
    if (score > 100) {
        return 1;
    }
    return 0;
};

Simple.prototype.isForceAvailable = function () {
    return false;
};

Simple.prototype.getCurrentPercent = function () {
    var total = this.getRequiredStars();
    var percent = cleverapps.meta.stars / total * 100;
    return (percent < 100) ? percent : 100;
};

Simple.prototype.onOpen = function (f) {
    var scene = cleverapps.scenes.getRunningScene();
    cleverapps.focusManager.compound(f, [
        function (f) {
            cleverapps.meta.resetStars();
            var bundles = this.getBundles();
            if (bundles) {
                cleverapps.bundleLoader.loadBundles(bundles);
            }
            connector.social.markAchievement({});
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.SIMPLE_METHA_LEVELUP);
            f();
        }.bind(this),
        function (f) {
            cleverapps.meta.trigger("moveNext", f);
        },
        function (f) {
            if (cleverapps.config.rpg) {
                f();
            } else {
                scene.moveNextBg(f);
            }
        },
        function (f) {
            cleverapps.meta.afterMoveNext(f);
        }
    ]);
};

Placements.ENTRIES["simpleMoveNext"] = {
    type: Placements.FREE_FOCUS_MAIN,
    priority: 10,

    filter: function () {
        return cleverapps.meta.getMainObject().checkComplete();
    },

    action: function () {
        cleverapps.focusManager.display({
            focus: "simpleMoveNextLocation",
            control: ["progress_view"],
            actions: [
                function (f) {
                    cleverapps.meta.completeCurrentLocation(f);
                },
                function (f) {
                    cleverapps.meta.moveNextLocation(f);
                },
                function (f) {
                    if (cleverapps.playButton && cleverapps.playButton.passedLevel && cleverapps.config.name !== "woodenblock") {
                        cleverapps.playButton.animatePassLevel();
                    }
                    f();
                }
            ]
        });
    }
};