/**
 * Created by Mikhail Menshenin on 28.06.2024
 */

var SimpleStarCountView = cc.Node.extend({
    ctor: function () {
        this._super();

        var bg = this.bg = cleverapps.UI.createScale9Sprite(bundles.simple.frames.percentage_bg);
        bg.setPositionRound({ x: { align: "center" }, y: { align: "center" } });
        this.addChild(bg);

        var text = this.text = new TextWithIcon(this.getText(), {
            font: cleverapps.styles.FONTS.SIMPLESTARCOUNTVIEW_TEXT,
            icons: {
                "**": bundles.reward_icons.frames.simple_star_png
            }
        });
        text.setPositionRound({ x: { align: "center" }, y: { align: "center" } });
        this.addChild(text);

        this.updateSize();

        this.starsCount = cleverapps.meta.stars;
        cleverapps.meta.on("changeStars", this.animateChange.bind(this), this);
    },

    updateSize: function () {
        var styles = cleverapps.styles.SimpleStarCountView;
        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            this.text.setFontSize(styles.text.vertical.font.size);
        } else {
            this.text.setFontSize(styles.text.horizontal.font.size);
        }
        this.text.setPositionRound(styles.text.position);
        this.setContentSize(this.text.getContentSize());
        cleverapps.UI.wrapWithPadding(this, styles.padding, true);
        this.bg.setPositionRound(styles.bg);
        this.bg.resizeKeepBorderRaius(this.getContentSize());
    },

    animateChange: function (silent) {
        var stars = Math.min(cleverapps.meta.stars, cleverapps.meta.getMainObject().getRequiredStars());

        if (silent) {
            this.starsCount = stars;
            this.text.setString(this.getText());
            this.updateSize();
            return;
        }

        var template = this.getText(this.starsCount).replace(this.starsCount, "%{value}");
        this.text.runAction(
            cc.sequence(
                new SimplestarCountTo(0.6, this.starsCount, stars, template),
                new cc.DelayTime(0.6),
                new cc.ScaleTo(0.15, 1.25),
                new cc.ScaleTo(0.15, 1),
                new cc.CallFunc(function () {
                    this.starsCount = stars;
                }.bind(this))
            )
        );
    },

    getText: function (stars) {
        var requiredStars = cleverapps.meta.getMainObject().getRequiredStars();
        stars = stars === undefined ? Math.min(cleverapps.meta.stars, requiredStars) : stars;

        return stars + "/" + requiredStars + " **";
    }
});

cc.Scale9Sprite.prototype.resizeKeepBorderRaius = function (size, options) {
    options = options || {};
    var direction = options.direction || cleverapps.UI.HORIZONTAL;
    var height = this.height;
    var width = this.width;
    var scale = 1;

    if (direction === cleverapps.UI.HORIZONTAL) {
        scale = size.height / height;
        width = size.width / scale;
    } else {
        scale = size.width / width;
        height = size.height / scale;
    }

    this.setContentSize(width, height);
    this.setScale(scale);
};

var SimplestarCountTo = cc.ActionInterval.extend({
    ctor: function (duration, from, to, temlate) {
        cc.ActionInterval.prototype.ctor.call(this, duration);

        this._from = from;
        this._to = to;
        this._template = temlate;
    },

    update: function (time) {
        time = this._computeEaseTime(time);

        var value = this._from + Math.floor((this._to - this._from) * time);

        this.target.setString(this._template.replace("%{value}", value));
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    SIMPLESTARCOUNTVIEW_TEXT: {
        name: "default",
        size: 31
    }
});

cleverapps.styles.SimpleStarCountView = {
    padding: {
        x: 30,
        y: 12
    },

    bg: {
        x: { align: "center" },
        y: { align: "center" }
    },

    text: {
        position: { x: { align: "center" }, y: { align: "center" } },
        vertical: {
            font: {
                size: 28
            }
        },
        horizontal: {
            font: {
                size: 31
            }
        }
    }
};