/**
 * Created by Slava on 04.06.2024
 */

if (cleverapps.config.name === "yatzy") {
    RewardsConfig.Packs = {
        0: {
            hard: 50,
            soft: 200
        },
        1: {
            hard: 150,
            soft: 1000
        },
        2: {
            hard: 700,
            soft: 6000
        }
    };

    RewardsConfig.Tournament = {
        places: [{
            hard: 300
        }, {
            hard: 250
        }, {
            hard: 200
        }, {
            hard: 100
        }, {
            hard: 50
        }]
    };

    RewardsConfig.DailyTask = {
        easy: [
            {
                soft: 15
            }
        ],
        medium: [
            {
                soft: 30
            }
        ],
        hard: [
            {
                soft: 45
            }
        ],
        completeAll: [
            {
                soft: 50,
                unlimitedLives: "30 minutes"
            }
        ]
    };

    RewardsConfig.MiniGame = [
        {
            soft: 20,
            unlimitedLives: "20 minutes"
        },
        {
            soft: 20,
            unlimitedLives: "30 minutes"
        },
        {
            soft: 10,
            unlimitedLives: "30 minutes"
        },
        {
            soft: 50,
            unlimitedLives: "10 minutes"
        }
    ];
}

if (cleverapps.config.name === "coinkingdom") {
    RewardsConfig.Packs = {
        0: {
            hard: 50,
            soft: 200
        },
        1: {
            hard: 150,
            soft: 1000
        },
        2: {
            hard: 700,
            soft: 6000
        }
    };

    RewardsConfig.StickersBook = [
        {
            hard: 100
        },
        {
            hard: 100
        },
        {
            hard: 100
        },
        {
            hard: 100
        },
        {
            hard: 100
        },
        {
            hard: 100
        },
        {
            hard: 350
        },
        {
            hard: 350
        },
        {
            hard: 350
        },
        {
            hard: 350
        }
    ];
}