/**
 * Created by Mikhail Menshenin on 15.08.2024
 */

SideBarSlotView.prototype.insert = function (icon) {
    this.iconView = icon;
    this.addChild(icon);

    var styles = cleverapps.styles.SideBarSlot;

    icon.setPositionRound(this.iconView.model.countOfSlots() > 1 ? styles.iconsGroup : styles.icon);

    cleverapps.focusManager.registerControl(icon.model.control || this.getControlName(), this, icon.model.hiddenByDefault || Object.values(cleverapps.Environment).filter(function (scene) {
        return ![cleverapps.Environment.SCENE_MAIN, cleverapps.Environment.SCENE_SLOT_MACHINE].includes(scene);
    }));
};