/**
 * Created by Slava on 07.06.2024
 */

BaseProductTile.prototype.addBackground = cleverapps.extendFunc(BaseProductTile.prototype.addBackground, function () {
    var styles = this.styles.background;

    var lightBg = cleverapps.UI.createScale9Sprite(
        bundles.tile_shop.frames.shop_coins_light_bg,
        styles.adjustBackgroundCapInsets ? cleverapps.UI.Scale9Rect.TwoPixelXY : cleverapps.UI.Scale9Rect.Default
    );
    lightBg.setContentSize2(this.width, this.height);
    lightBg.setAnchorPoint(0.5, 0.5);
    lightBg.setPositionRound(this.width / 2, this.height / 2);
    this.addChild(lightBg);

    var darkBg = cleverapps.UI.createScale9Sprite(
        bundles.tile_shop.frames.shop_coins_dark_bg,
        styles.adjustBackgroundCapInsets ? cleverapps.UI.Scale9Rect.TwoPixelXY : cleverapps.UI.Scale9Rect.Default
    );
    darkBg.setContentSize2(lightBg.width - styles.darkBg.padding.width, lightBg.height - styles.darkBg.padding.height);
    darkBg.setAnchorPoint(0.5, 0.5);
    darkBg.setPositionRound(styles.darkBg);
    this.addChild(darkBg);
});

BaseProductTile.prototype.wrapTitleWithBg = cleverapps.extendFunc(BaseProductTile.prototype.wrapTitleWithBg, function (title) {
    if (!title) {
        return;
    }

    var styles = this.styles.title;

    var background = this.titleBg = cleverapps.UI.createScale9Sprite(this.getTitleImage());
    background.setAnchorPoint(0, 0.5);
    background.setLocalZOrder(styles.zOrder);
    background.setPositionRound(styles.bg);
    background.setContentSize2(styles.width || background.width, styles.height || background.height);

    var node = new cc.Node();
    node.setAnchorPoint(0.5, 0.5);
    node.setContentSize(this.width, styles.height || background.height);

    if (title.fitTo) {
        title.fitTo(background.width * 0.9);
    }

    title.setPositionRound(styles.text);
    background.addChild(title);
    node.addChild(background);
    return node;
});