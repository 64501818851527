/**
 * Created by mac on 8/20/23
 */

var RollButtonView = cleverapps.UI.Button.extend({
    avoidNode: "RollButtonView",
    ctor: function (game) {
        this.game = game;

        this._super({
            type: cleverapps.styles.UI.Button.Images.button_blue,
            width: cleverapps.styles.RollButtonView.horizontal.width,
            height: cleverapps.styles.RollButtonView.horizontal.height,
            content: new TextWithIcon("RollButton.Roll", {
                font: cleverapps.styles.FONTS.ROLL_BUTTON_TEXT,
                icons: {
                    "$$": bundles.buttons.frames.hard_roll,
                    "##": bundles.buttons.frames.advertising_roll
                }
            }),
            contentOff: new TextWithIcon("[{ RollButton.noRollsLeft }]", {
                font: cleverapps.styles.FONTS.ROLL_OFF_BUTTON_TEXT,
                icons: {
                    "[{": bundles.buttons.frames.arrow_left,
                    "}]": bundles.buttons.frames.arrow_right
                }
            }),
            onClicked: this.onClick.bind(this),
            click_effect: bundles.game.urls.click_roll
        });

        this.createCircle();

        this.onChangeRolls();

        this.updateSize();

        this.visible = false;
        game.on("showRollButton", this.animateShow.bind(this), this);
        game.on("changeRolls", this.onChangeRolls.bind(this), this);
        game.on("hideRollButton", this.animateHide.bind(this), this);
    },

    onClick: function () {
        if (this.prolongationOffer) {
            this.game.prolongation.acceptOffer(this.prolongationOffer);
        } else {
            this.game.roll();
        }
    },

    animateShow: function () {
        this.visible = true;
        this.setCascadeOpacityEnabledRecursively(true);
        this.setOpacity(0);

        this.runAction(
            new cc.FadeIn(0.6)
        );
    },

    onChangeRolls: function () {
        this.circle.setVisible(!this.game.noRollsLeft());
        this.number.setString(this.game.getRolls());

        this.prolongationOffer = this.game.getProlongationOffer();
        if (this.prolongationOffer) {
            this.enable();
            this.setType(cleverapps.styles.UI.Button.Images.button_green);
            if (this.prolongationOffer.text === "FREE") {
                this.setString("RollButton.BonusRoll");
            } else {
                this.setString("RollButton.Roll " + this.prolongationOffer.text);
            }
        } else if (this.game.noRollsLeft()) {
            this.disable();
            this.setType(cleverapps.styles.UI.Button.Images.off_button);
            this.onMouseOut();
        } else {
            this.enable();
            this.setType(cleverapps.styles.UI.Button.Images.button_blue);
            this.setString("RollButton.Roll");
        }
    },

    animateHide: function () {
        this.setCascadeOpacityEnabledRecursively(true);
        this.runAction(
            new cc.Sequence(
                new cc.FadeOut(0.3),
                new cc.CallFunc(function () {
                    this.visible = false;
                }.bind(this))
            )
        );
    },

    createCircle: function () {
        this.circle = new cc.Sprite(bundles.yatzy.frames.roll_ball_full);
        this.circle.setPositionRound(cleverapps.styles.RollButtonView.circle);

        this.circle.setCascadeOpacityEnabled(true);

        this.number = cleverapps.UI.generateImageText(this.game.rolls, cleverapps.styles.FONTS.WHITE_TEXT);
        this.number.setPositionRound(cleverapps.styles.RollButtonView.circle.number);
        this.number.setFontSize(30);

        this.number.setCascadeOpacityEnabled(true);

        this.circle.addChild(this.number);

        this.addChild(this.circle);
    },

    updateSize: function () {
        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            this.resize(cleverapps.styles.RollButtonView.vertical.width, cleverapps.styles.RollButtonView.vertical.height);
        } else {
            this.resize(cleverapps.styles.RollButtonView.horizontal.width, cleverapps.styles.RollButtonView.horizontal.height);
        }
        this.onChangeRolls();
    },

    onMouseOver: function () {
        if (!this.hover && !this.disabled) {
            this.hover = cleverapps.UI.createScale9Sprite(bundles.buttons.frames.hover_blue);
            this.hover.setAnchorPoint(0.5, 0.5);
            this.hover.setPositionRound(cleverapps.styles.RollButtonView.hover);
            this.hover.setContentSize(this.getContentSize().width + cleverapps.styles.RollButtonView.hover.width, this.getContentSize().height + cleverapps.styles.RollButtonView.hover.height);
            this.addChild(this.hover, -1);
        }
    },

    onMouseOut: function () {
        if (this.hover) {
            this.hover.removeFromParent();
            delete this.hover;
        }
    },

    applyInteractiveScale: function () {
        var states = this.getInteractiveStates();
        if (states.pressed) {
            this.onMouseOut();
        } else if (states.mouseover) {
            this.onMouseOver();
        }
        this._innerUpdateBgContent();
    }
});

cleverapps.styles.RollButtonView = {
    horizontal: {
        width: 600,
        height: 125
    },

    vertical: {
        width: 450,
        height: 100
    },

    circle: {
        x: { align: "right", dx: 9 },
        y: { align: "top", dy: 9 },
        number: {
            x: { align: "center", dx: 2 },
            y: { align: "center", dy: 4 }
        }
    },

    hover: {
        width: 35,
        height: 35,
        x: { align: "center" },
        y: { align: "center", dy: 5 }
    }
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ROLL_BUTTON_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },
    ROLL_OFF_BUTTON_TEXT: {
        size: 32,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.overrideTemplates(cleverapps.styles.UI.Button.Images, {
    off_button: {
        button_png: bundles.buttons.frames.off_button_png,
        button_on_png: bundles.buttons.frames.off_button_png,
        button_off_png: bundles.buttons.frames.off_button_png
    }
});
