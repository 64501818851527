/**
 * Created by vladislav on 23/03/2023
 */

var SnapScrollView = cleverapps.UI.ScrollView.extend({
    ctor: function (items, options) {
        options.direction = options.direction === cleverapps.UI.ScrollView.DIR_VERTICAL
            ? cleverapps.UI.VERTICAL
            : cleverapps.UI.HORIZONTAL;

        this.page = options.page || 0;

        this.items = items;
        this.onChangePage = options.onChangePage;

        var content = new cleverapps.Layout(items, options);

        if (this.getPagesAmount() < 2) {
            this.touchScrollDisabled = true;
        }

        this._super(content, Object.assign(options, {
            scrollHandler: SnapScrollHandler
        }));

        this.pageSize = options.pageSize || {
            width: items[1].width * items[1].scale,
            height: items[1].height * items[1].scale
        };

        var width = options.width || this.pageSize.width * (options.pagesCount || 1);
        var height = options.height || this.pageSize.height * 1.7;

        this.setContentSize(width, height);

        this.scrollTo(this.items[this.page]);
    },

    getPagesAmount: function () {
        return this.items.length;
    }
});

var SnapScrollHandler = function (target, options) {
    cleverapps.UI.ScrollHandler.call(this, target, options);
};

SnapScrollHandler.prototype = Object.create(cleverapps.UI.ScrollHandler.prototype);
SnapScrollHandler.prototype.constructor = SnapScrollHandler;

SnapScrollHandler.prototype.handleRelease = function () {
    this.inTouch = false;

    var pos = this.target.getContainerPosition();

    var offset;
    if (this.target.dir === cleverapps.UI.ScrollView.DIR_HORIZONTAL) {
        offset = (pos.x + (this.target.innerContent.width - this.target.pageSize.width) / 2) / this.target.pageSize.width;
    } else {
        offset = (pos.y + (this.target.innerContent.height - this.target.pageSize.height) / 2) / this.target.pageSize.height;
    }

    var page = cleverapps.clamp(Math.round(offset), 0, this.target.getPagesAmount() - 1);

    this.target.page = page;

    this.target.scrollTo(this.target.items[page], 0.3, {
        easing: cc.easeIn(2),
        callback: function () {
            if (this.target.onChangePage) {
                this.target.onChangePage(page);
            }
        }.bind(this)
    });
};

SnapScrollHandler.prototype.afterContainerMove = function () {
    cleverapps.UI.ScrollHandler.prototype.afterContainerMove.call(this);
};