/**
 * Created by Mikhail Menshenin on 01.07.2024
 */

var MedalSidebarIcon = function () {
    SideBarIcon.call(this, {
        animation: bundles.sidebar.jsons.medal_icon_json,
        targets: ["MedalSidebarIcon", "medals"],
        control: "MedalSidebarIcon"
    });

    this.resetState();

    aisensia.medalCollection.on("updateCurrency", this.resetState.bind(this));
};

MedalSidebarIcon.prototype = Object.create(SideBarIcon.prototype);
MedalSidebarIcon.prototype.constructor = MedalSidebarIcon;

MedalSidebarIcon.prototype.resetState = function () {
    this.available = true;

    this.setAttention(aisensia.medalCollection.isAttentionRequired());
};

MedalSidebarIcon.prototype.onPressed = function () {
    cleverapps.focusManager.display({
        focus: "MedalSidebarIconClicked",
        control: [],
        action: function (f) {
            new MedalCollectionWindow(aisensia.medalCollection);
            cleverapps.focusManager.onceNoWindowsListener = f;
        }
    });
};
