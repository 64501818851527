/**
* Created by Mikhail Menshenin on 23.03.2024
*/

var MedalItemView = cc.Sprite.extend({
    ctor: function (field, level) {
        this._super(bundles.medalcollection.frames["medal_" + level]);
        this.setLocalZOrder(100);

        var levelText = this.levelText = cleverapps.UI.generateOnlyText("MedalCollectionWindow.MedalLevelLabel", cleverapps.styles.FONTS.MEDAL_ITEM_LEVEL_TEXT, { level: level });
        levelText.setPositionRound(cleverapps.styles.MedalItemView.level);
        this.addChild(levelText);

        cleverapps.UI.onDrag(this, {
            instantDrag: true,
            followPointer: this.createListener(this.followPointer.bind(this)),
            onDragStart: this.createListener(this.onDragStart.bind(this)),
            onDragEnd: this.createListener(this.onDragEnd.bind(this))
        });

        this.field = field;
    },

    placeOnTop: function () {
        this.replaceParentSamePlace(cleverapps.windows.currentWindow().window);
    },

    hideLevelText: function () {
        this.levelText.runAction(cc.fadeOut(0.03));
    },

    showLevelText: function () {
        this.levelText.runAction(cc.fadeIn(0.03));
    },

    followPointer: function (touch) {
        this.dragPosition = this.parent.convertTouchToNodeSpace(touch);
        this.updateDragPosition();
    },

    onDragStart: function () {
        if (this.field.game.counter.isActive()) {
            return false;
        }

        this.placeOnTop();
        this.setLocalZOrder(100);

        var medalCell = this.field.getMedalCell(this);
        this.field.game.drag(medalCell);

        this.animateStartDrag();

        this.levelText.runAction(cc.fadeOut(0.1));

        return true;
    },

    onDragEnd: function () {
        this.stopDragAnimation();

        var medalCell = this.field.getMedalCell(this);
        var nearestCell = this.field.getNearestCell(this);

        this.field.game.drop(medalCell, nearestCell);
    },

    stopDragAnimation: function () {
        this.stopAllActions();
        clearInterval(this.startDragInterval);
    },

    animateStartDrag: function () {
        this.stopDragAnimation();
        cleverapps.audio.playSound(bundles.game.urls.drag_effect);
        this.getParent().setLocalZOrder(2);

        var timeout = 100, beginTime = Date.now(), beginScale = this.getScale();
        var offset = cleverapps.styles.MedalItemView.dragOffset;
        this.dragPosition = {
            x: this.x,
            y: this.y
        };
        this.dragOffset = {
            dx: 0,
            dy: 0
        };
        this.startDragInterval = setInterval(function () {
            var deltaTime = (Date.now() - beginTime) / timeout;
            if (deltaTime >= 1) {
                deltaTime = 1;
                clearInterval(this.startDragInterval);
            }
            this.dragOffset = {
                dx: offset.dx * deltaTime,
                dy: offset.dy * deltaTime
            };
            this.setScale(beginScale + (1 - beginScale) * deltaTime);
            this.updateDragPosition();
        }.bind(this), 15);
    },

    updateDragPosition: function () {
        this.setPosition(this.dragPosition.x + this.dragOffset.dx, this.dragPosition.y + this.dragOffset.dy);
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    MEDAL_ITEM_LEVEL_TEXT: {
        size: 28,
        color: cleverapps.styles.COLORS.WHITE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_INVERSED_SHADOW
    }
});

cleverapps.styles.MedalItemView = {
    dragOffset: {
        dx: 0,
        dy: 40
    },

    icon: {
        x: { align: "center" },
        y: { align: "center" }
    },

    level: {
        x: { align: "center", dx: 28 },
        y: { align: "center", dy: -44 }
    }
};
