/**
 * Created by Mikhail Menshenin on 2/29/24
 */

var MedalCollectionWindow = CleverappsWindow.extend({
    onWindowLoaded: function (game) {
        var styles = cleverapps.styles.MedalCollectionWindow;

        this.game = game;

        this.game.on("unlocked", this.showUnlocked.bind(this), this);

        this._super({
            title: "MedalCollectionWindow.Title",
            name: "MedalCollectionWindow",
            content: this.createContent(styles),
            styles: styles,
            noPadding: true,
            foreground: bundles.windows.frames.window_foreground_png,
            help: function () {
                new GuideWindow({
                    name: "MedalCollectionGuideWindow",
                    bundle: bundles.medalcollectionguidewindow
                });
            }
        });

        this.tabs.onTabChanged = this.onTabChanged.bind(this);

        this.tabs.activateTab("merge");

        if (cleverapps.config.debugMode) {
            cleverapps.UI.onClick(this.windowTitle, function () {
                this.game.addMore(1);
            }.bind(this));
        }
    },

    createContent: function (styles) {
        var content = this.content = new cc.Node();
        content.setContentSize2(styles);

        var tabs = this.tabs = new Tabs(this.createTabs(), styles.tabs);
        tabs.setPositionRound(styles.tabs);
        content.addChild(tabs);

        return content;
    },

    createTabs: function () {
        return {
            merge: {
                icon: bundles.medalcollection.frames.tab_icon_merge_png,
                generator: this.createTab.bind(this, MedalMergeTabView)
            },
            library: {
                icon: bundles.medalcollection.frames.tab_icon_collection_png,
                generator: this.createTab.bind(this, MedalLibraryTabView)
            }
        };
    },

    createTab: function (TabView) {
        var tab = new TabView(this.game);
        tab.setPositionRound({ x: { align: "center" }, y: { align: "top" } });
        this.content.addChild(tab);
        return tab;
    },

    showUnlocked: function (level) {
        new MedalUnlockedWindow(level);
    },

    onTabChanged: function (tabId) {
        var styles = cleverapps.styles.MedalCollectionWindow;

        this.closeButton.setType(styles.close[tabId]);

        this.foreground.setVisible(tabId === "library");
    },

    listBundles: function () {
        return ["medalcollection"];
    }
});

cleverapps.styles.MedalCollectionWindow = {
    width: 832,
    height: 1216,

    tabs: {
        direction: cleverapps.UI.VERTICAL,
        margin: -20,
        width: 130,
        height: 160,

        x: { align: "left", dx: -120 },
        y: { align: "top", dy: -140 }
    },

    helpButton: {
        x: { align: "left", dx: 20 },
        y: { align: "center", dy: 2 }
    },

    close: {
        merge: {
            button_png: bundles.buttons_main.frames.window_close_white_png,
            button_on_png: bundles.buttons_main.frames.window_close_white_on_png
        },
        library: {
            button_png: bundles.buttons_main.frames.window_close_png,
            button_on_png: bundles.buttons_main.frames.window_close_on_png
        }
    }
};
